import React, { useEffect, useState } from 'react'
import { bool, date, object, string, number } from 'yup'
import { Formik } from 'formik'
import Select from 'react-select'
import dayjs from 'dayjs'
import Calendar from 'react-datepicker'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Campaign } from '../../types'
import { getCompanies } from '../../store/reducers/api/companyReducer'
import * as userRoles from '../../constants/userRoles'
import 'react-datepicker/dist/react-datepicker.css'

const CampaignEditor = ({ id, initialCampaign, save, editMode }: { id: string, initialCampaign: Partial<Campaign>, save: Function, editMode: boolean }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)
  const isUpdating = useAppSelector((state) => state.apiCampaign.isLoading)
  const companies = useAppSelector((state) => state.apiCompany.companies)
  const shippingMethods = useAppSelector((state) => state.apiShippingMethod.shippingMethods)

  const [perPage] = useState(100)
  const [page] = useState(1)

  const token = currentUser?.token
  const role = profile?.role

  const dispatch = useAppDispatch()

  const commonSchema = {
    name: string()
      .required('Campaign Name is required')
      .min(2, 'Enter a valid Campaign Name')
      .max(32, 'Campaign Name is too long'),
    type: string().required('Campaign Type is required').oneOf(['onboarding', 'birthday', 'christmas', 'marketing']),
    status: string().label('Campaign Status').oneOf(['draft', 'submitted']).default('draft'),
    description: string().max(250, 'Description is too long').nullable()
  }

  const campaignSchema = object().shape(commonSchema)

  const adminCampaignSchema = object().shape({
    ...commonSchema,
    companyId: string().uuid().label('Company')
      .when([], {
        is: () => !editMode,
        then: string().required('Company is required'),
        otherwise: string().optional()
      }),
    correctionQuota: number().label('Correction Quota').typeError('Correction Quota must be a number'),
    isQuotaEnabled: bool().label('Is Quota Enabled'),
    lastQuotaResetDate: date().nullable().label('Last Quota Reset Date'),
    isExceedQuotaEnabled: bool().label('Exceed Quota Enabled'),
    isExceedStockEnabled: bool().label('Exceed Stock Enabled'),
    isNoteEnabled: bool().label('Is Note Enabled'),
    isActive: bool().label('Is Active').default(true),
    isHidden: bool().label('Is Hidden').default(false),
    shippingMethodType: number().label('Shipping Method Type').required().typeError('Select Shipping Method Type'),
    shippingMethodIsDropShipping: bool().label('Is Dropshipping').default(false),
    isBulkCreateEnabled: bool().label('Is Bulk Create Enabled').default(false),
    includeStartDate: bool().label('Include Start Date').default(false)
  })

  const saveCampaign = (id: string, campaign: any, signal: AbortSignal) => {
    dispatch(save({ id, token, campaign, signal }))
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    token && dispatch(getCompanies({ token, perPage, page, signal }))
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <div>
      <Formik
        validationSchema={(role === userRoles.ADMIN) ? adminCampaignSchema : campaignSchema}
        enableReinitialize
        initialValues={{
          ...initialCampaign,
          lastQuotaResetDate: initialCampaign.lastQuotaResetDate ? dayjs(initialCampaign.lastQuotaResetDate).toDate() : null,
          companyId: ''
        }}
        onSubmit={(
          {
            companyId, name, correctionQuota, lastQuotaResetDate, isQuotaEnabled,
            isExceedQuotaEnabled, isNoteEnabled, type, status, description, isHidden, isActive, isBulkCreateEnabled,
            shippingMethodType, shippingMethodIsDropShipping, isExceedStockEnabled, includeStartDate
          },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal
          const commonCampaignProperties = {
            name,
            type,
            status,
            description
          }

          const campaign = role === userRoles.ADMIN
            ? {
                ...commonCampaignProperties,
                correctionQuota: correctionQuota || 0,
                lastQuotaResetDate,
                isQuotaEnabled,
                isExceedQuotaEnabled,
                isNoteEnabled,
                isHidden,
                isActive,
                isBulkCreateEnabled,
                shippingMethodType,
                shippingMethodIsDropShipping,
                isExceedStockEnabled,
                includeStartDate
              }
            : commonCampaignProperties

          const companyIdOrCampaignId = (role === userRoles.ADMIN && !editMode) ? companyId : id

          if (token && companyIdOrCampaignId) {
            saveCampaign(companyIdOrCampaignId, campaign, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            {(role === userRoles.ADMIN && !editMode) && (
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label
                      htmlFor="company"
                      className="form-label"
                    >
                      Company
                    </label>
                    <Select
                      className={`${
                        touched.companyId &&
                        errors.companyId
                          ? 'is-invalid'
                          : ''
                      }`}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor,
                          '&:hover': {
                            borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor
                          }
                        })
                      }}
                      isClearable
                      inputId="company"
                      name="companyId"
                      aria-label="Company"
                      options={companies}
                      getOptionLabel={(company) => `${company.name} - ${company.domain ?? 'Domain not set'}`}
                      getOptionValue={(company) => String(company.id)}
                      onChange={(selectedOption) => {
                        const selectedCompanyId = selectedOption?.id ?? ''
                        setFieldValue('companyId', selectedCompanyId)
                      }}
                      onBlur={handleBlur}
                      isLoading={isLoading}
                    />
                    <div
                      id="validationCompanyFeedback"
                      className="invalid-feedback"
                    >
                      {errors.companyId}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="campaignName"
                    className="form-label"
                  >
                    Campaign Name
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    type="text"
                    className={`form-control ${
                      touched.name &&
                      errors.name
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="campaignName"
                    name="name"
                    placeholder=""
                    autoComplete="off"
                  />
                  <div
                    id="validationCampaignNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="campaignType"
                    className="form-label"
                  >
                    Campaign Type
                  </label>
                  <select
                    aria-label="Campaign Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type}
                    className={`form-select ${
                      touched.type &&
                      errors.type
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="campaignType"
                    name="type"
                  >
                    <option value="">Select Campaign Type</option>
                    <option>onboarding</option>
                    <option>birthday</option>
                    <option>christmas</option>
                    <option>marketing</option>
                  </select>
                  <div
                    id="validationCampaignTypeFeedback"
                    className="invalid-feedback"
                  >
                    {errors.type}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="campaignStatus"
                    className="form-label"
                  >
                    Campaign Status
                  </label>
                  <select
                    aria-label="Campaign Status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.status}
                    className={`form-select ${
                      touched.status &&
                      errors.status
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="campaignStatus"
                    name="status"
                  >
                    <option value="">Select Campaign Status</option>
                    <option>draft</option>
                    <option>submitted</option>
                  </select>
                  <div
                    id="validationCampaignStatusFeedback"
                    className="invalid-feedback"
                  >
                    {errors.status}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="campaignDescription"
                    className="form-label"
                  >
                    Description
                  </label>
                  <textarea
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description || ''}
                    className={`form-control ${
                      touched.description &&
                      errors.description
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="campaignDescription"
                    name="description"
                    placeholder=""
                  />
                  <div
                    id="validationCampaignDescriptionFeedback"
                    className="invalid-feedback"
                  >
                    {errors.description}
                  </div>
                </div>
              </div>
            </div>

            {
              role === userRoles.ADMIN && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="shippingMethodType"
                        className="form-label"
                      >
                        Shipping Method Type
                      </label>
                      <select
                        aria-label="Shipping Method Type"
                        onChange={(event) => {
                          handleChange(event)

                          const foundShippingMethodType = shippingMethods.find(shippingMethod => String(shippingMethod.shippingType) === String(event.target.value))
                          if (foundShippingMethodType) {
                            foundShippingMethodType && setFieldValue('shippingMethodIsDropShipping', String(foundShippingMethodType.isDropShipping))
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.shippingMethodType || ''}
                        className={`form-select ${
                          touched.shippingMethodType &&
                          errors.shippingMethodType
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="shippingMethodType"
                        name="shippingMethodType"
                      >
                        <option value="">Select Shipping Method Type</option>
                        {
                          shippingMethods.map(shippingMethod => (<option key={shippingMethod.id} value={shippingMethod.shippingType}>{shippingMethod.name} {shippingMethod.isDropShipping && '- Dropshipping'}</option>))
                        }
                      </select>
                      <div
                        id="validationShippingMethodTypeFeedback"
                        className="invalid-feedback"
                      >
                        {errors.shippingMethodType}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="campaignIsNoteEnabled"
                        className="form-label"
                      >
                        Enable Note
                      </label>
                      <select
                        aria-label="Enable Note"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.isNoteEnabled)}
                        className={`form-select ${
                          touched.isNoteEnabled &&
                          errors.isNoteEnabled
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="campaignIsNoteEnabled"
                        name="isNoteEnabled"
                      >
                        <option value="">Select Enable Note</option>
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                      </select>
                      <div
                        id="validationIsNoteEnabledFeedback"
                        className="invalid-feedback"
                      >
                        {errors.isNoteEnabled}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            {
              role === userRoles.ADMIN && (
                <>
                  <div className="row">
                    <hr className="mt-2" />
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="campaignIsQuotaEnabled"
                          className="form-label"
                        >
                          Is Quota Enabled
                        </label>
                        <select
                          aria-label="Is Quota Enabled"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isQuotaEnabled)}
                          className={`form-select ${
                            touched.isQuotaEnabled &&
                            errors.isQuotaEnabled
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="campaignIsQuotaEnabled"
                          name="isQuotaEnabled"
                        >
                          <option value="">Select Is Quota Enabled</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsQuotaEnabledFeedback"
                          className="invalid-feedback"
                        >
                          {errors.isQuotaEnabled}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="campaignCorrectionQuota"
                          className="form-label"
                        >
                          Correction Quota
                        </label>
                        <input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.correctionQuota || 0}
                          type="number"
                          className={`form-control ${
                            touched.correctionQuota &&
                            errors.correctionQuota
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="campaignCorrectionQuota"
                          name="correctionQuota"
                          min={0}
                        />
                        <div
                          id="validationCorrectionCampaignQuotaFeedback"
                          className="invalid-feedback"
                        >
                          {errors.correctionQuota}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="campaignTotalOrderedQuota"
                          className="form-label"
                        >
                          Quota
                        </label>
                        <input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.totalOrderedQuota || 0}
                          type="number"
                          className={'form-control'}
                          id="campaignTotalOrderedQuota"
                          name="quota"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="lastQuotaResetDate" className="form-label">
                          Last Quota Reset Date
                        </label>
                        <div
                          className={`${
                            errors.lastQuotaResetDate &&
                            touched.lastQuotaResetDate
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <Calendar
                            id="lastQuotaResetDate"
                            name="lastQuotaResetDate"
                            onChange={(date: Date) => {
                              setFieldValue('lastQuotaResetDate', date)
                            }}
                            selected={values.lastQuotaResetDate}
                            className={`form-control ${
                              errors.lastQuotaResetDate &&
                              touched.lastQuotaResetDate
                                ? 'is-invalid'
                                : ''
                            }`}
                            dateFormat={'dd/MM/yyyy'}
                            autoComplete={'off'}
                          />
                        </div>
                        <div
                          id="validationLastQuotaResetDateFeedback"
                          className="invalid-feedback"
                        >
                          {errors.lastQuotaResetDate}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="campaignIsExceedQuotaEnabled"
                          className="form-label"
                        >
                          Allow Over Ordering <span className="fw-semibold">(Quota)</span>
                        </label>
                        <select
                          aria-label="Allow Over Ordering"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isExceedQuotaEnabled)}
                          className={`form-select ${
                            touched.isExceedQuotaEnabled &&
                            errors.isExceedQuotaEnabled
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="campaignIsExceedQuotaEnabled"
                          name="isExceedQuotaEnabled"
                        >
                          <option value="">Select Allow Over Ordering</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsExceedQuotaEnabledFeedback"
                          className="invalid-feedback"
                        >
                          {errors.isExceedQuotaEnabled}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="campaignIsExceedStockEnabled"
                          className="form-label"
                        >
                          Allow Over Ordering <span className="fw-semibold">(Stock)</span>
                        </label>
                        <select
                          aria-label="Allow Over Ordering (Stock)"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isExceedStockEnabled)}
                          className={`form-select ${
                            touched.isExceedStockEnabled &&
                            errors.isExceedStockEnabled
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="campaignIsExceedStockEnabled"
                          name="isExceedStockEnabled"
                        >
                          <option value="">Select Allow Over Ordering</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsExceedStockEnabledFeedback"
                          className="invalid-feedback"
                        >
                          {errors.isExceedStockEnabled}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="campaignIsHidden"
                          className="form-label"
                        >
                          Is Hidden
                        </label>
                        <select
                          aria-label="Is Hidden"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isHidden)}
                          className={`form-select ${
                            touched.isHidden &&
                            errors.isHidden
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="campaignIsHidden"
                          name="isHidden"
                        >
                          <option value="">Select Is Hidden</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsHidden"
                          className="invalid-feedback"
                        >
                          {errors.isHidden}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="campaignIsActive"
                          className="form-label"
                        >
                          Is Active
                        </label>
                        <select
                          aria-label="Is Active"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isActive)}
                          className={`form-select ${
                            touched.isActive &&
                            errors.isActive
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="campaignIsActive"
                          name="isActive"
                        >
                          <option value="">Select Is Active</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsActive"
                          className="invalid-feedback"
                        >
                          {errors.isActive}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="isBulkCreateEnabled"
                          className="form-label"
                        >
                          Allow Bulk Create
                        </label>
                        <select
                          aria-label="isBulkCreateEnabled"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isBulkCreateEnabled)}
                          className={`form-select ${
                            touched.isBulkCreateEnabled &&
                            errors.isBulkCreateEnabled
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="isBulkCreateEnabled"
                          name="isBulkCreateEnabled"
                        >
                          <option value="">Select is Bulk Create Enabled</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="isBulkCreateEnabled"
                          className="invalid-feedback"
                        >
                          {errors.isBulkCreateEnabled}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="includeStartDate"
                          className="form-label"
                        >
                          Include Start Date
                        </label>
                        <select
                          aria-label="Include Start Date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.includeStartDate)}
                          className={`form-select ${
                            touched.includeStartDate &&
                            errors.includeStartDate
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="includeStartDate"
                          name="includeStartDate"
                        >
                          <option value="">Select Include Start Date</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="includeStartDate"
                          className="invalid-feedback"
                        >
                          {errors.includeStartDate}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }

            <div>
              {
                Object.keys(errors).length > 0 && (<div className="text-danger small">Kindly check the inputs and fix any errors</div>)
              }
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isLoading || isUpdating
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CampaignEditor
