import React from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

const SalutationEditor = ({ id, name, save, mode = 'none' }: { id: string, name: string, save: Function, mode?: 'edit' | 'add' | 'none' }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiSalutation.isLoading)
  const error = useAppSelector((state) => state.apiSalutation.error)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const salutationSchema = object({
    name: string()
      .required('Name is required')
      .max(64, 'Name name is too long')
  })
  return (
    <div>
      <Formik
        validationSchema={salutationSchema}
        enableReinitialize
        initialValues={{
          name
        }}
        onSubmit={({ name }, actions) => {
          const controller = new AbortController()
          const signal = controller.signal
          const salutation = {
            name
          }
          if (token) {
            dispatch(
              save({
                salutationId: id,
                token,
                salutation,
                signal
              })
            )
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor={`name${mode}`} className="form-label">
                    Name
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    type="text"
                    className={`form-control ${
                      errors.name && touched.name && errors.name
                        ? 'is-invalid'
                        : ''
                    }`}
                    id={`name${mode}`}
                    name="name"
                    placeholder="Enter name"
                    autoComplete="off"
                  />
                  <div
                    className="invalid-feedback"
                  >
                    {errors.name || error?.errors?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                className="btn btn-secondary mt-2 me-2"
                data-bs-target="#salutationEditModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                type="button"
                disabled={isLoading || isSubmitting}
              >
                <i className="bi bi-list"></i> View All
              </button>
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={isLoading || isSubmitting}
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default SalutationEditor
