import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import Pagination from '../../components/Pagination'
import Progress from '../../components/loaders/Progress'
import ModalBox from '../../components/ModalBox'
import EmailRichTextEditor from './EmailRichTextEditor'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  createEmailTemplate,
  getAllEmailTemplates,
  resetEmailTemplateError,
  updateEmailTemplate
} from '../../store/reducers/api/emailTemplateReducer'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import TableHead from '../../components/Orders/Table/TableHead'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { getEmailTemplateTypes } from '../../store/reducers/api/emailTemplateTypeReducer'

const placeholders = ['[app]', '[firstname]', '[lastname]', '[title]', '[salutation]', '[otp]', '[url]', '[company]', '[role]', '[useremail]', '[adminemail]', '[mailer]', '[salesmailer]', '[password]']

const index = () => {
  const token = useAppSelector((state) => state.apiAuth.currentUser?.token)
  const emailTemplateTypes = useAppSelector((state) => state.apiEmailTemplateType.emailTemplateTypes)
  const errorEmailTemplateTypes = useAppSelector((state) => state.apiEmailTemplateType.error)
  const isLoading = useAppSelector((state) => state.apiEmailTemplate.isLoading)
  const emailTemplates = useAppSelector((state) => state.apiEmailTemplate.emailTemplates)
  const errorEmailTemplates = useAppSelector((state) => state.apiEmailTemplate.error)
  const message = useAppSelector((state) => state.apiEmailTemplate.message)
  const metadata = useAppSelector((state) => state.apiEmailTemplate.metadata)
  const [editMode, setEditMode] = useState<boolean>(true)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [initialEmailTemplate, setInitialEmailTemplate] = useState({
    id: '',
    subject: '',
    template: '',
    emailTemplateTypeId: '',
    isDefault: false
  })
  const [initialPlaceholders, setInitialPlaceholders] = useState<string[]>(placeholders)
  const quillRef = useRef<any>(null)

  const dispatch = useAppDispatch()

  const columns = [
    {
      name: 'ID',
      className: ''
    },
    {
      name: 'Subject',
      className: ''
    },
    {
      name: 'Type',
      className: ''
    },
    {
      name: 'Updated Date',
      className: ''
    },
    {
      name: 'Actions',
      className: 'text-end'
    }
  ]

  const emailTemplateSchema = object({
    templateType: string().label('Email Template Type').required(),
    subject: string().label('Subject').required(),
    template: string().label('Template').required()
  })

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched
  } = useFormik({
    initialValues: {
      subject: initialEmailTemplate.subject || '',
      templateType: initialEmailTemplate.emailTemplateTypeId || '',
      template: initialEmailTemplate.template || '',
      isDefault: initialEmailTemplate.isDefault
    },
    onSubmit: ({ subject, template, templateType: emailTemplateTypeId }) => {
      const controller = new AbortController()
      const signal = controller.signal
      if (token) {
        if (editMode) {
          dispatch(
            updateEmailTemplate({
              token,
              emailTemplate: {
                subject,
                emailTemplateTypeId,
                template
              },
              id: initialEmailTemplate.id,
              signal
            })
          )
        } else {
          dispatch(
            createEmailTemplate({
              token,
              emailTemplate: {
                subject,
                emailTemplateTypeId,
                template
              },
              signal
            })
          )
        }
      }
    },
    validationSchema: emailTemplateSchema,
    enableReinitialize: true
  })

  const handleEmailTemplateRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    token && dispatch(getAllEmailTemplates({ token, perPage, page, signal }))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const page = 1
    const perPage = 50

    token && dispatch(getEmailTemplateTypes({ token, page, perPage, signal }))

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (message && token) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getAllEmailTemplates({ token, perPage, page, signal }))
      dismissModal('addEmailTemplate')
    }
    return () => {
      controller.abort()
    }
  }, [message])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    token && dispatch(getAllEmailTemplates({ token, perPage, page, signal }))

    return () => {
      controller.abort()
    }
  }, [page, perPage])

  useEffect(() => {
    if ((errorEmailTemplateTypes && errorEmailTemplateTypes.errors) || (errorEmailTemplates && errorEmailTemplates.errors)) {
      const payload = {
        title: 'Error',
        message: errorEmailTemplateTypes?.errors?.message || errorEmailTemplates?.errors?.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetEmailTemplateError())
    }
  }, [errorEmailTemplateTypes, errorEmailTemplates])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5">
                <i className="bi bi-envelope-paper me-2"></i>Email Templates
              </p>
              {
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    title="Add Email Template"
                    aria-label="addEmailTemplate"
                    data-bs-toggle="modal"
                    data-bs-target="#addEmailTemplate"
                    disabled={false}
                    onClick={() => {
                      setFieldValue('subject', '')
                      setFieldValue('templateType', '')
                      setFieldValue('template', '')
                      setEditMode(false)
                      setInitialEmailTemplate({
                        id: '',
                        subject: '',
                        template: '',
                        emailTemplateTypeId: '',
                        isDefault: false
                      })
                      setInitialPlaceholders(placeholders)
                    }}
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-1 d-none d-md-inline-block">
                      Add Template
                    </span>
                  </button>
                  <button
                    type="button"
                    title="Refresh Email Templates"
                    aria-label="Refresh Email Templates"
                    className="btn btn-outline-dark ms-2 ms-md-3"
                    onClick={() => handleEmailTemplateRefresh()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                </ul>
              }
            </div>
            {isLoading ? (<Progress />) : (<hr className="border border-primary border-1 opacity-50"></hr>)}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <TableHead columns={columns} />
                <tbody>
                  {isLoading
                    ? (Array.from(Array(perPage).keys()).map((n: number) => (<SkeletonTableRow key={n} colSpan={columns.length} />)))
                    : emailTemplates && emailTemplates.length > 0
                      ? (
                          emailTemplates.map((template) => (
                            <tr key={template.id} className={initialEmailTemplate.id === template.id ? 'table-primary' : ''}>
                              <td
                                title={String(template.id)}
                                className="user-select-all"
                                onClick={() => navigator.clipboard.writeText(String(template.id))}
                              >
                                {(template.id).substring(0, 8)}
                              </td>
                              <td>{template.subject}</td>
                              <td className="fw-semibold">
                                {template.isDefault && 'Default'} {template.emailTemplateType.name}
                              </td>
                              <td className="text-nowrap">
                                {dayjs.utc(template.updatedAt).local().format('ll')}
                              </td>
                              <td className="text-end">
                                <div
                                  className="d-flex flex-row float-end"
                                  role="group"
                                  aria-label="Actions"
                                >
                                  <button
                                    className="btn btn-outline-dark btn-round"
                                    type="button"
                                    title="Edit Email Template"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addEmailTemplate"
                                    onClick={() => {
                                      setInitialEmailTemplate({
                                        id: template.id,
                                        subject: template.subject,
                                        template: template.template,
                                        emailTemplateTypeId: template.emailTemplateType.id,
                                        isDefault: template.isDefault
                                      })
                                      setInitialPlaceholders(template.emailTemplateType.placeholders)
                                      setEditMode(true)
                                      setFieldValue('subject', template.subject)
                                      setFieldValue(
                                        'templateType',
                                        template.emailTemplateType.id
                                      )
                                      setFieldValue('template', template.template)
                                    }}
                                  >
                                    <PencilIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        )
                      : (
                        <tr>
                          <td colSpan={columns.length} className="text-center">
                            No email templates available yet
                          </td>
                        </tr>
                        )}
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: (metadata.page - 1) * metadata.perPage
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              isTrackingPage
            />
          </div>
        </div>
        <ModalBox
          isLoading={isLoading}
          id={'addEmailTemplate'}
          ariaLabelledBy={'addEmailTemplateLabel'}
          modalSize="modal-lg"
          modalHeader={`${!editMode ? 'Create' : 'Edit'} Email Template`}
          modalHeaderIcon={!editMode ? (<i className="bi-plus-circle me-1"></i>) : (<i className="bi bi-pencil-square  me-1"></i>)}
        >
          <div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label htmlFor="subject" className="form-label">
                      Subject
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                      type="text"
                      className={`form-control ${
                        errors.subject && touched.subject
                          ? 'is-invalid'
                          : ''
                      }`}
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      autoComplete="off"
                      readOnly={values.isDefault}
                    />
                    <div
                      id="validationSubjectFeedback"
                      className="invalid-feedback"
                    >
                      {errors.subject}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label htmlFor="templateType" className="form-label">
                      Email Template Type
                    </label>
                    <select
                      aria-label="Email Template Type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.templateType}
                      className={`form-select ${
                        errors.templateType &&
                        touched.templateType
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="templateType"
                      name="templateType"
                      autoComplete="off"
                      disabled={values.isDefault}
                    >
                      <option value="">Select Email Template Type</option>
                      {emailTemplateTypes.map((templateType: any) => (
                        <option key={templateType.id} value={templateType.id}>
                          {templateType.name}
                        </option>
                      ))}
                    </select>
                    <div
                      id="validationBundleNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.templateType}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <span className="form-label d-inline-block">
                      Template
                    </span>
                    <EmailRichTextEditor
                      ref={quillRef}
                      placeholders={initialPlaceholders}
                      handleTextChange={(value) => {
                        setFieldValue('template', value)
                      }}
                      textContent={values.template}
                      stageWidth={'100%'}
                      className={`${
                        touched.template &&
                        errors.template
                          ? 'is-invalid'
                          : ''
                      }`}
                      border={(touched.template && errors.template) ? '1px solid #dc3545' : 'none'}
                      readOnly={values.isDefault}
                    />
                    <div
                      id="validationBundleNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.template}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end">
                <button type="submit" className="btn btn-primary mt-2" disabled={values.isDefault}>
                  <i className="bi bi-save"></i> Save
                </button>
              </div>
            </form>
          </div>
        </ModalBox>
      </div>
    </main>
  )
}
export default index
