import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import ModalBox from '../ModalBox'
import CSVDownloader from './CSVDownloader'
import { getAllPagesForUsers } from '../../store/reducers/api/usersReducer'
import { getAllPagesForOrders } from '../../store/reducers/api/orderReducer'
import { getAllPagesForCompanyUsers } from '../../store/reducers/api/companyReducer'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import Progress from '../loaders/Progress'
import { getMappedUserData, getNormalizedOrderData } from '../../utils/getNormalizedExportData'
import { getAllPagesForCampaignOrders, getAllPagesForCampaignOrdersForBundle } from '../../store/reducers/api/campaignReducer'

const CSVExporter = () => {
  const areAllPagesForOrders = useAppSelector((state) => state.apiOutbound.areAllPagesForOrders)
  const areAllPagesForUsers = useAppSelector((state) => state.apiUsers.areAllPagesForUsers)
  const areAllPagesForCompanyUsers = useAppSelector((state) => state.apiCompany.areAllPagesForCompanyUsers)
  const areAllPagesForCampaignOrders = useAppSelector((state) => state.apiCampaign.areAllPagesForCampaignOrders)
  const areAllPagesForCampaignOrdersForBundle = useAppSelector((state) => state.apiCampaign.areAllPagesForCampaignOrdersForBundle)
  const campaignOrdersExportCampaignId = useAppSelector((state) => state.apiCampaign.campaignOrdersExportCampaignId)
  const campaignOrdersExportJFSKU = useAppSelector((state) => state.apiCampaign.campaignOrdersExportJFSKU)
  const companyId = useAppSelector((state) => state.apiCompany.exportCompanyId)
  const companyName = useAppSelector((state) => state.apiCompany.exportCompanyName)
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)

  const [orderCSVData, setOrderCSVData] = useState<any[]>([])
  const [usersCSVData, setUsersCSVData] = useState<any[]>([])
  const [companyUsersCSVData, setCompanyUserCSVData] = useState<any[]>([])
  const [usersError, setUsersError] = useState<boolean>(false)
  const [ordersError, setOrdersError] = useState<boolean>(false)
  const [campaignOrdersError, setCampaignOrdersError] = useState<boolean>(false)
  const [companyUsersError, setCompanyUsersError] = useState<boolean>(false)
  const [isMinimized, setIsMinimized] = useState(false)
  const [componentType, setComponentType] = useState<'orders' | 'campaignOrdersForBundle' | 'campaignOrders' | null>(null)

  const token = currentUser?.token
  const dispatch = useAppDispatch()

  const chevronUpPath = 'M12.707 10.707a1 1 0 01-1.414 0L8 7.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'
  const chevronDownPath = 'M3.293 5.293a1 1 0 011.414 0L8 8.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'

  const pathData = isMinimized ? chevronUpPath : chevronDownPath

  const svgUrl = `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='${pathData}'/%3e%3c/svg%3e`

  const showSnackbar = () => {
    return areAllPagesForOrders || areAllPagesForUsers || areAllPagesForCompanyUsers || areAllPagesForCampaignOrders || areAllPagesForCampaignOrdersForBundle
  }

  const componentTypeName = {
    orders: 'orders',
    campaignOrdersForBundle: 'campaign orders for bundle',
    campaignOrders: 'campaign orders'
  }

  useEffect(() => {
    const worker = new Worker(new URL('../../utils/csvBackgroundWorker.ts', import.meta.url))
    if (areAllPagesForOrders) {
      setComponentType('orders')
      worker.postMessage({ type: 'totalOrders', token })
      worker.onmessage = ({ data }) => {
        const { type, payload } = data
        switch (type) {
          case 'totalOrders': {
            if (payload !== 'error') {
              const csvData: any = getNormalizedOrderData(payload)
              openModal('exportOrdersToCSV')
              dispatch(getAllPagesForOrders(false))
              setOrderCSVData(csvData)
            } else {
              setOrdersError(true)
              dispatch(getAllPagesForOrders(false))
              openModal('exportOrdersToCSV')
            }
            break
          }
          default:
            return null
        }
      }
    }
    if (areAllPagesForCampaignOrders) {
      setComponentType('campaignOrders')
      worker.postMessage({ type: 'totalCampaignOrders', token, campaignId: campaignOrdersExportCampaignId })
      worker.onmessage = ({ data }) => {
        const { type, payload } = data
        switch (type) {
          case 'totalCampaignOrders': {
            if (payload !== 'error') {
              const csvData: any = getNormalizedOrderData(payload)
              openModal('exportCampaignOrdersToCSV')
              dispatch(getAllPagesForCampaignOrders(false))

              setOrderCSVData(csvData)
            } else {
              setCampaignOrdersError(true)
              dispatch(getAllPagesForCampaignOrders(false))
              openModal('exportCampaignOrdersToCSV')
            }
            break
          }
          default:
            return null
        }
      }
    }
    if (areAllPagesForCampaignOrdersForBundle) {
      setComponentType('campaignOrdersForBundle')
      worker.postMessage({ type: 'totalCampaignOrdersForBundle', token, campaignId: campaignOrdersExportCampaignId, jfsku: campaignOrdersExportJFSKU })
      worker.onmessage = ({ data }) => {
        const { type, payload } = data
        switch (type) {
          case 'totalCampaignOrdersForBundle': {
            if (payload !== 'error') {
              const csvData: any = getNormalizedOrderData(payload)
              openModal('exportCampaignOrdersToCSV')
              dispatch(getAllPagesForCampaignOrdersForBundle(false))
              setOrderCSVData(csvData)
            } else {
              setCampaignOrdersError(true)
              dispatch(getAllPagesForCampaignOrdersForBundle(false))
              openModal('exportCampaignOrdersToCSV')
            }
            break
          }
          default:
            return null
        }
      }
    }
    if (areAllPagesForUsers) {
      worker.postMessage({ type: 'totalUsers', token })
      worker.onmessage = ({ data }) => {
        const { type, payload } = data
        switch (type) {
          case 'totalUsers': {
            if (payload !== 'error') {
              const csvData = getMappedUserData(payload, 'all')
              setUsersCSVData(csvData)
              dispatch(getAllPagesForUsers(false))
              openModal('exportUsersToCSV')
            } else {
              setUsersError(true)
              dispatch(getAllPagesForUsers(false))
              openModal('exportUsersToCSV')
            }
            break
          }
          default:
            return null
        }
      }
    }
    if (areAllPagesForCompanyUsers) {
      worker.postMessage({ type: 'totalCompanyUsers', token, companyId })
      worker.onmessage = ({ data }) => {
        const { type, payload } = data
        if (payload !== 'error') {
          if (type === 'totalCompanyUsers') {
            const userData = getMappedUserData(payload, 'company', companyName)
            setCompanyUserCSVData(userData)
            dispatch(getAllPagesForCompanyUsers(false))
            openModal('exportCompanyUsersToCSV')
          }
        } else {
          setCompanyUsersError(true)
          dispatch(getAllPagesForCompanyUsers(false))
          openModal('exportCompanyUsersToCSV')
        }
      }
    }
    return () => {
      worker.terminate()
    }
  }, [areAllPagesForOrders, areAllPagesForUsers, areAllPagesForCompanyUsers, areAllPagesForCampaignOrders, areAllPagesForCampaignOrdersForBundle])

  return (
    <div>
      <div className="toast-container position-fixed bottom-0 start-0 p-3">
        <div
          className={`toast fade ${showSnackbar() ? 'show' : 'hide'} text-bg-info`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <i className="bi bi-filetype-csv rounded me-2"></i>
            <strong className="me-auto"></strong>
            <small className="text-muted">{(dayjs().format('LLLL'))?.toString()}</small>
            <button
              type="button"
              className="btn-close"
              style={{
                background: `url("${svgUrl}")`
              }}
              aria-label="Minimize"
              onClick={() => {
                setIsMinimized(!isMinimized)
              }}
            />
          </div>
          <Progress backgroundColor="dark" marginBottom={false} />
          {
            isMinimized
              ? (<></>)
              : (<div className="toast-body fw-semibold">Exporting your data.</div>)
          }
        </div>

      </div>
      <ModalBox modalHeader="Export Orders Data to CSV" id="exportOrdersToCSV">
        <div>
          {!ordersError
            ? (<p className="h6">Your export is ready to download</p>)
            : (<p className="text-danger h6">An error occurred while downloading your csv</p>)
          }
        </div>
        <div className="mt-4 text-end">
          {!ordersError
            ? (
              <CSVDownloader
                btnText="Download"
                data={orderCSVData}
                fileName={`All orders export ${currentUser?.company ? currentUser?.company.name : ''} ${dayjs().format('DD.MM.YYYY')}`}
              />
              )
            : (
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (componentType === 'orders') {
                    dispatch(getAllPagesForOrders(true))
                    setOrdersError(false)
                  }
                  dismissModal('exportOrdersToCSV')
                }}
              >
                Retry
              </button>
              )
          }
        </div>
      </ModalBox>
      <ModalBox modalHeader={`Export Campaign Orders ${componentType === 'campaignOrdersForBundle' ? 'for Bundle' : ''} Data to CSV`} id="exportCampaignOrdersToCSV">
        <div>
          {!campaignOrdersError
            ? (<p className="h6">Your export is ready to download</p>)
            : (<p className="text-danger h6">An error occurred while downloading your csv</p>)
          }
        </div>
        <div className="mt-4 text-end">
          {!campaignOrdersError
            ? (
              <CSVDownloader
                btnText="Download"
                data={orderCSVData}
                fileName={`All ${componentType ? componentTypeName[componentType] : ''} export ${currentUser?.company ? currentUser?.company.name : ''} ${dayjs().format('DD.MM.YYYY')}`}
              />
              )
            : (
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (componentType === 'campaignOrders') {
                    dispatch(getAllPagesForCampaignOrders(true))
                    setCampaignOrdersError(false)
                  }
                  if (componentType === 'campaignOrdersForBundle') {
                    dispatch(getAllPagesForCampaignOrdersForBundle(true))
                    setCampaignOrdersError(false)
                  }
                  dismissModal('exportCampaignOrdersToCSV')
                }}
              >
                Retry
              </button>
              )}
        </div>
      </ModalBox>
      <ModalBox modalHeader="Export Users Data To CSV" id="exportUsersToCSV">
        <div>
          {!usersError
            ? (<p className="h6">Your export is ready to download</p>)
            : (<p className="text-danger h6">An error occurred while downloading your csv</p>)
          }
        </div>
        <div className="mt-4 text-end">
          {!usersError
            ? (
              <CSVDownloader
                btnText="Download"
                data={usersCSVData}
                fileName={`All users export ${dayjs().format('DD.MM.YYYY')}`}
              />
              )
            : (
              <button
                className="btn btn-primary"
                onClick={() => {
                  dispatch(getAllPagesForUsers(true))
                  setUsersError(false)
                  dismissModal('exportUsersToCSV')
                }}
              >
                Retry
              </button>
              )}
        </div>
      </ModalBox>
      <ModalBox
        modalHeader="Export Company Users Data to CSV"
        id="exportCompanyUsersToCSV"
      >
        <div>
          {!companyUsersError
            ? (<p className="h6">Your export is ready to download</p>)
            : (<p className="text-danger h6">An error occurred while downloading your csv</p>)
          }
        </div>
        <div className="mt-4 text-end">
          {!companyUsersError
            ? (
              <CSVDownloader
                btnText="Download"
                data={companyUsersCSVData}
                fileName={`All company users export ${companyName || ''} ${dayjs().format('DD.MM.YYYY')}`}
              />
              )
            : (
              <button
                className="btn btn-primary"
                onClick={() => {
                  dispatch(getAllPagesForCompanyUsers(true))
                  setCompanyUsersError(false)
                  dismissModal('exportCompanyUsersToCSV')
                }}
              >
                Retry
              </button>
              )}
        </div>
      </ModalBox>
    </div>
  )
}

export default CSVExporter
