import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { Tab } from 'bootstrap'
import useTemplateSelector from '../../components/Canvas/TemplateSelector'
import ImageEditor from '../../components/Canvas/ImageEditor'
import RichTextEditor from '../../components/Canvas/RichTextEditor'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getAllCards } from '../../store/reducers/api/pictureReducer'
import { createCampaignCardSetting, createCampaignCardTemplate, getCampaignById, getCampaignCardTemplates, resetCampaignMessage } from '../../store/reducers/api/campaignReducer'
import Progress from '../../components/loaders/Progress'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import Pagination from '../../components/Pagination'
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal'
import { CardSetting, CardTemplate, Module, Permission } from '../../types'
import * as appModules from '../../constants/appModules'
import hasPermission from '../../utils/checkPermissions'
import * as userRoles from '../../constants/userRoles'
import { READ, READWRITE } from '../../constants/permissions'
import { deleteCardTemplate, resetCardTemplateMessage } from '../../store/reducers/api/cardTemplateReducer'
import { setToast } from '../../store/reducers/toastReducer'
import { TrashIcon } from '../../components/icons/TrashIcon'
import { dismissModal } from '../../utils/dismissModal'

const Canvas = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const cardTemplates = useAppSelector((state) => state.apiCampaign.cardTemplates)
  const cardTemplatesMetadata = useAppSelector((state) => state.apiCampaign.cardTemplatesMetadata)
  const isLoadingCardTemplates = useAppSelector((state) => state.apiCardTemplate.isLoading)
  const isLoadingCampaignCardTemplates = useAppSelector((state) => state.apiCampaign.isLoadingCardTemplates)
  const messageCampaign = useAppSelector((state) => state.apiCampaign.message)
  const messageCardTemplate = useAppSelector((state) => state.apiCardTemplate.message)
  const campaign = useAppSelector((state) => state.apiCampaign.campaign)
  const isLoadingCampaign = useAppSelector((state) => state.apiCampaign.isLoading)
  const [frontOrientation, setFrontOrientation] = useState<'portrait' | 'landscape'>('portrait')
  const [backOrientation, setBackOrientation] = useState<'portrait' | 'landscape'>('portrait')
  const [, setFormat] = useState<'a4' | 'letter' | number[]>('a4')
  const [, setCompressPdf] = useState<boolean>(true)
  const [imageStageWidth, setImageStageWidth] = useState(window.innerWidth)
  const [imageStageHeight, setImageStageHeight] = useState(window.innerHeight)
  const [documentStageWidth, setDocumentStageWidth] = useState('210mm')
  const [documentStageHeight, setDocumentStageHeight] = useState('297mm')
  const [textContent, setTextContent] = useState('')
  const [side, setSide] = useState<'front' | 'back'>('front')
  const [toggleRender, setToggleRender] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const storedCardTemplatePerPage = Number(localStorage.getItem('perPageCardTemplates')) || 10

  const [initialCardTemplate, setInitialCardTemplate] = useState<Partial<CardTemplate>>({
    id: '',
    name: '',
    description: '',
    front: '',
    back: '',
    frontOrientation: null,
    backOrientation: null,
    isDraft: false
  })

  const [perPageCardTemplate, setPerPageCardTemplate] = useState(storedCardTemplatePerPage)
  const [pageCardTemplate, setPageCardTemplate] = useState(1)
  const [defaultCardTemplate, setDefaultCardTemplate] = useState<CardTemplate | null>(null)
  const {
    render: renderTemplateSelector, activeTemplate, image,
    setActiveTemplate, perPageTemplateSelector, setNextPageToken, nextPageToken,
    templateTypeFilter, setTemplateTypeFilter, setActiveFront, activeFront
  } = useTemplateSelector()
  const TemplateSelector = () => renderTemplateSelector

  const quillRef = useRef<any>(null)
  const imageStageRef = useRef<any>(null)

  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []

  const token = currentUser?.token
  const userId = profile?.id
  const role = profile?.role || userRoles.USER
  const companyOwnerId = profile?.company?.owner?.id
  const isOwner = companyOwnerId && userId === companyOwnerId
  const companyId = profile?.company?.id
  const pdfExt = 'pdf'
  const baseURL = process.env.REACT_APP_PDF_SERVICE_API_URL

  const dispatch = useAppDispatch()
  const { campaignId } = useParams()

  const handleCardTemplateShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageCardTemplate(1)
    setPerPageCardTemplate(Number(event.target.value))
    localStorage.setItem('perPageCardTemplates', event.target.value)
  }

  const handleCardTemplatePageChange = (page: number) => {
    setPageCardTemplate(page)
  }

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return isOwner || hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const handleFrontPdfDownload = () => {
    setIsExporting(true)
    const download = {
      imageUrl: activeFront,
      frontOrientation,
      exportSide: 'front',
      placeholders: {
        salutation: profile?.salutation || '',
        title: profile?.title || '',
        firstname: profile?.firstName || '',
        lastname: profile?.lastName || ''
      }
    }
    if (token) {
      fetch(`${baseURL}/api/greeting-cards/download`, {
        method: 'POST',
        body: JSON.stringify({ download }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob()
          } else {
            throw new Error('Failed to download the PDF')
          }
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `big-little-things-${Date.now()}-front.${pdfExt}`
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
        .catch((error) => {
          const payload = {
            title: 'Error',
            message: error.message,
            isVisible: true,
            timestamp: dayjs().format('LT'),
            type: 'danger'
          }
          dispatch(setToast(payload))
        }).finally(() => {
          setIsExporting(false)
        })
    }
  }

  const handleBackPdfDownload = () => {
    setIsExporting(true)
    const download = {
      htmlText: textContent,
      backOrientation,
      exportSide: 'back',
      placeholders: {
        salutation: profile?.salutation || '',
        title: profile?.title || '',
        firstname: profile?.firstName || '',
        lastname: profile?.lastName || ''
      },
      barcodeValue: getBarcode(campaign?.cardSetting).barcodeValue,
      barcodeFormat: getBarcode(campaign?.cardSetting).barcodeType
    }
    if (token) {
      fetch(`${baseURL}/api/greeting-cards/download`, {
        method: 'POST',
        body: JSON.stringify({ download }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob()
          } else {
            throw new Error('Failed to download the PDF')
          }
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `big-little-things-${Date.now()}-back.${pdfExt}`
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
        .catch((error) => {
          const payload = {
            title: 'Error',
            message: error.message,
            isVisible: true,
            timestamp: dayjs().format('LT'),
            type: 'danger'
          }
          dispatch(setToast(payload))
        }).finally(() => {
          setIsExporting(false)
        })
    }
  }

  const handleCardTemplateRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && campaignId && isAllowed(appModules.CAMPAIGNS)) {
      dispatch(getCampaignCardTemplates({ id: campaignId, token, perPage: perPageCardTemplate, page: pageCardTemplate, signal }))
    }
  }

  const handleActionSelection = (cardTemplate: CardTemplate) => {
    setActiveTemplate(null)
    setActiveFront(cardTemplate.front)
    setInitialCardTemplate(cardTemplate)
    setTextContent(cardTemplate.back)
    if (cardTemplate.backOrientation === 'landscape') {
      setDocumentStageWidth('297mm')
      setDocumentStageHeight('210mm')
      setBackOrientation('landscape')
    } else {
      setDocumentStageWidth('210mm')
      setDocumentStageHeight('297mm')
      setBackOrientation('portrait')
    }
  }

  const handleCardTemplateSelection = (cardTemplate: CardTemplate) => {
    if (role === userRoles.ADMIN || campaign?.cardSetting?.isFrontSelectable) {
      if (initialCardTemplate.id === cardTemplate.id) {
        setInitialCardTemplate({
          id: '',
          name: '',
          description: '',
          front: '',
          back: '',
          frontOrientation: null,
          backOrientation: null,
          isDraft: false
        })
        setActiveTemplate(null)
        setTextContent('')
      } else {
        handleActionSelection(cardTemplate)
      }
    } else {
      const payload = {
        title: 'Info',
        message: 'Template selection has been disabled',
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'info'
      }
      dispatch(setToast(payload))
    }
  }

  const handleFilter = (type: 'general' | 'company') => {
    const nextPageTokenOption = type === 'general' ? 'Cg5jYXJkcy9nZW5lcmFsLw==' : 'CgZjYXJkcy8='
    setNextPageToken(nextPageTokenOption)
    setTemplateTypeFilter(type)
  }

  const generateThumbnail = (cardTemplate: CardTemplate) => {
    return cardTemplate.front
  }

  const getBarcode = (cardSetting: CardSetting | null | undefined): { barcodeValue: string | null, barcodeType: 'itf14' | 'ean13' | 'ean8' | 'upc' | 'upce' | undefined } => {
    const determineEANBarcodeType = (barcode: string) => {
      if (/^\d{8}$/.test(barcode)) {
        return 'ean8'
      } else if (/^\d{13}$/.test(barcode)) {
        return 'ean13'
      } else if (/^\d{14}$/.test(barcode)) {
        return 'itf14'
      } else {
        return undefined
      }
    }
    const determineUPCBarcodeType = (barcode: string) => {
      if (/^\d{12}$/.test(barcode)) {
        return 'upc'
      } else if (/^\d{6}(\d{2})?$/.test(barcode)) {
        return 'upce'
      } else {
        return undefined
      }
    }
    if (cardSetting) {
      if (cardSetting.eanBarcode) {
        return {
          barcodeValue: cardSetting.eanBarcode,
          barcodeType: determineEANBarcodeType(cardSetting.eanBarcode)
        }
      }
      if (cardSetting.upcBarcode) {
        return {
          barcodeValue: cardSetting.upcBarcode,
          barcodeType: determineUPCBarcodeType(cardSetting.upcBarcode)
        }
      }
    }
    return {
      barcodeValue: null,
      barcodeType: undefined
    }
  }

  useEffect(() => {
    if (companyId === undefined || companyId === null) {
      setTemplateTypeFilter('general')
    }
  }, [companyId])

  useEffect(() => {
    // Update the Stage width and height based on the loaded image
    if (image) {
      if (image.height > image.width) {
        setFrontOrientation('portrait')
        !initialCardTemplate.backOrientation && setBackOrientation('portrait')
      } else {
        setFrontOrientation('landscape')
        !initialCardTemplate.backOrientation && setBackOrientation('landscape')
      }
    }
  }, [image])

  useEffect(() => {
    setFormat('a4')
    setCompressPdf(true)
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const perPage = perPageTemplateSelector
    const pageToken = nextPageToken
    const filter = (companyId && templateTypeFilter === 'company') ? `filter[companyId]=${companyId}` : ''

    if (token) {
      dispatch(getAllCards({ token, perPage, pageToken, filter, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [perPageTemplateSelector, nextPageToken, templateTypeFilter])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && campaignId && isAllowed(appModules.CAMPAIGNS)) {
      dispatch(getCampaignCardTemplates({ id: campaignId, token, perPage: perPageCardTemplate, page: pageCardTemplate, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if ((token && campaignId) && (messageCampaign) && isAllowed(appModules.CAMPAIGNS)) {
      const payload = {
        title: 'Success',
        message: messageCampaign,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))

      dispatch(getCampaignCardTemplates({ id: campaignId, token, perPage: perPageCardTemplate, page: pageCardTemplate, signal }))
      dispatch(getCampaignById({ id: campaignId, token, signal }))
      dispatch(resetCampaignMessage())
    }
  }, [messageCampaign])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if ((token && campaignId) && (messageCardTemplate) && isAllowed(appModules.CAMPAIGNS)) {
      const payload = {
        title: 'Success',
        message: messageCardTemplate,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))

      dispatch(getCampaignCardTemplates({ id: campaignId, token, perPage: perPageCardTemplate, page: pageCardTemplate, signal }))
      dispatch(resetCardTemplateMessage())
      dismissModal('confirmationModal')
    }
  }, [messageCardTemplate])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && campaignId && isAllowed(appModules.CAMPAIGNS)) {
      dispatch(getCampaignById({ id: campaignId, token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (initialCardTemplate.front) {
      if (activeFront !== initialCardTemplate.front) {
        setInitialCardTemplate({
          id: '',
          name: '',
          description: '',
          front: '',
          back: '',
          frontOrientation: null,
          backOrientation: null,
          isDraft: false
        })
        setTextContent('')
      }
    }
  }, [activeFront])

  useEffect(() => {
    if (frontOrientation === 'landscape') {
      setImageStageWidth(842)
      setImageStageHeight(595)
    } else {
      setImageStageWidth(595)
      setImageStageHeight(842)
    }
  }, [frontOrientation])

  useEffect(() => {
    if (backOrientation === 'landscape') {
      setDocumentStageWidth('297mm')
      setDocumentStageHeight('210mm')
    } else {
      setDocumentStageWidth('210mm')
      setDocumentStageHeight('297mm')
    }
  }, [backOrientation])

  useEffect(() => {
    if (campaign && campaign?.cardSetting) {
      setDefaultCardTemplate({
        id: campaign.cardSetting.id,
        name: 'Default',
        description: 'Default Campaign Card Template',
        isDraft: false,
        front: campaign.cardSetting?.defaultFront || '',
        back: campaign.cardSetting?.defaultBack || '',
        frontOrientation: campaign.cardSetting?.exportOrientation || 'portrait',
        backOrientation: campaign.cardSetting?.exportOrientation || 'portrait',
        createdAt: dayjs(campaign.cardSetting?.createdAt).toDate(),
        updatedAt: dayjs(campaign.cardSetting?.updatedAt).toDate()
      })
    }
  }, [campaign])

  if (!isLoadingCampaign && (role !== userRoles.ADMIN && !campaign?.cardSetting?.isEnabled)) {
    return (
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <p className="text-primary">The card editing feature for {`'${campaign?.name || 'this campaign'}'`} has been disabled by the admin.</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid px-4 py-4">
      <div className="card">
        <div className="m-4">
          <ul className="nav nav-tabs" id="canvasTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                disabled={isLoadingCampaign}
                className="nav-link active"
                id="templates-tab"
                data-bs-toggle="tab"
                data-bs-target="#templates"
                type="button"
                role="tab"
                aria-controls="templates"
                aria-selected="true"
              >
                Templates
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                disabled={isLoadingCampaign}
                className="nav-link"
                id="drafts-tab"
                data-bs-toggle="tab"
                data-bs-target="#drafts"
                type="button"
                role="tab"
                aria-controls="drafts"
                aria-selected="false"
              >
                Default & Drafts
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => {
                  setToggleRender(!toggleRender)
                }}
                className="nav-link"
                id="message-editor-tab"
                data-bs-toggle="tab"
                data-bs-target="#message-editor"
                type="button" role="tab"
                aria-controls="message-editor"
                aria-selected="false"
                disabled={!activeFront || isLoadingCampaign}
              >
                Message Editor
              </button>
            </li>
          </ul>
          <div className="tab-content mt-2">
            <div
              className="tab-pane fade show active"
              id="templates"
              role="tabpanel"
              aria-labelledby="templates-tab"
              tabIndex={0}
              data-html2canvas-ignore
            >
              <h4>Select a Template</h4>
              {companyId && <ul className="nav nav-pills mt-2 mb-2 border p-1 rounded" id="cardTemplateTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button disabled={isLoadingCampaign} className={`nav-link ${templateTypeFilter === 'company' && 'active'}`} id="company-tab" type="button" aria-controls="company-tab-pane" aria-selected="true" onClick={() => handleFilter('company')}>
                      Company Templates
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button disabled={isLoadingCampaign} className={`nav-link ${templateTypeFilter === 'general' && 'active'}`} id="general-tab" type="button" role="tab" aria-controls="general-tab-pane" aria-selected="false" onClick={() => handleFilter('general')}>
                      General Templates
                    </button>
                  </li>
                </ul>}
              <TemplateSelector />
            </div>
            <div
              className="tab-pane fade"
              id="drafts"
              role="tabpanel"
              aria-labelledby="drafts-tab"
              tabIndex={0}
              data-html2canvas-ignore
            >
              <h4>Select a Draft</h4>
              {isAllowed(appModules.CAMPAIGNS) &&
              <div className="card mt-2">
                <div className="m-4">
                  <div className="navbar navbar-expand mb-3">
                    <p className="h5"><i className="bi bi-card me-2"></i>Saved Card Templates</p>
                    <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                      <button
                        type="button"
                        title="Refresh"
                        aria-label="Refresh"
                        className="btn btn-outline-dark ms-2 ms-md-3"
                        onClick={() => handleCardTemplateRefresh()}
                      >
                        <i className="fas fa-refresh"></i>
                      </button>
                    </ul>
                  </div>
                  {isLoadingCampaign ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">#</th>
                          <th scope="col">Preview</th>
                          <th scope="col">Name</th>
                          <th scope="col">Status</th>
                          <th scope="col">Description</th>
                          <th scope="col" className="text-nowrap">Updated Date</th>
                          <th scope="col">
                            <span className="float-end">
                              Actions
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          defaultCardTemplate && (
                            <tr>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleCardTemplateSelection(defaultCardTemplate)}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="cardTemplateRadio"
                                  id="cardTemplateRadioDefault"
                                  value=""
                                  onChange={() => {}}
                                  checked={initialCardTemplate.id === defaultCardTemplate.id}
                                />
                              </td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleCardTemplateSelection(defaultCardTemplate)}
                              >
                                0
                              </td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleCardTemplateSelection(defaultCardTemplate)}
                              >
                                <img src={defaultCardTemplate.front} style={{ width: 40 }} className="img-thumbnail p-0 m-0" alt="Default Thumbnail"></img>
                              </td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleCardTemplateSelection(defaultCardTemplate)}
                              >
                                Default
                              </td>
                              <td><span className="badge text-bg-success">{defaultCardTemplate.name}</span></td>
                              <td>{defaultCardTemplate.description}</td>
                              <td>
                                {dayjs(defaultCardTemplate.updatedAt).format('lll')}
                              </td>
                              <td>
                                <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                  <button
                                    className="btn btn-outline-dark btn-round me-2"
                                    type="button"
                                    title="Default Card Template"
                                    disabled
                                  >
                                    <i className="bi bi-gear-fill"></i>
                                  </button>

                                  <button
                                    className="btn btn-outline-danger btn-round"
                                    type="button"
                                    title="Delete Card Template"
                                    disabled
                                  >
                                    <TrashIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        }
                        {
                          isLoadingCampaignCardTemplates
                            ? (
                                Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={8} actionQuantity={2} />)
                              )
                            : (
                                cardTemplates.length > 0
                                  ? (cardTemplates.map((cardTemplate: CardTemplate, index: number) => (
                                    <tr
                                      key={cardTemplate.id}
                                      className={`${initialCardTemplate.id === cardTemplate.id ? 'table-primary' : ''}`}
                                    >
                                      <td
                                        className="cursor-pointer"
                                        onClick={() => handleCardTemplateSelection(cardTemplate)}
                                      >
                                        <div>
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="cardTemplateRadio"
                                            id={`cardTemplateRadio${index}`}
                                            value=""
                                            aria-label={`Select Card Template for ${cardTemplate.name}`}
                                            checked={initialCardTemplate.id === cardTemplate.id}
                                            onChange={() => {}}
                                          />
                                        </div>
                                      </td>
                                      <td
                                        className="cursor-pointer"
                                        onClick={() => handleCardTemplateSelection(cardTemplate)}
                                      >
                                        {index + 1}
                                      </td>
                                      <td
                                        className="cursor-pointer"
                                        onClick={() => handleCardTemplateSelection(cardTemplate)}
                                      >
                                        <img src={generateThumbnail(cardTemplate)} style={{ width: 40 }} className="img-thumbnail p-0 m-0" alt={`${cardTemplate.name} thumbnail`}></img>
                                      </td>
                                      <td
                                        className="cursor-pointer"
                                        onClick={() => handleCardTemplateSelection(cardTemplate)}
                                      >
                                        {cardTemplate.name || '-'}
                                      </td>
                                      <td>
                                        {
                                          cardTemplate.isDraft
                                            ? (<span className="badge text-bg-warning">Draft</span>)
                                            : (<span className="badge text-bg-success">Final</span>)
                                        }
                                      </td>
                                      <td>{cardTemplate.description || '-'}</td>
                                      <td>
                                        {dayjs(cardTemplate.updatedAt).format('lll')}
                                      </td>
                                      <td className="text-center">
                                        <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                          {
                                            role === userRoles.ADMIN && (
                                              <button
                                                className="btn btn-outline-dark btn-round me-2"
                                                type="button"
                                                title="Set Default Card Template"
                                                data-bs-toggle="modal"
                                                data-bs-target="#actionConfirmationModal"
                                                onClick={() => {
                                                  handleActionSelection(cardTemplate)
                                                }}
                                              >
                                                <i className="bi bi-gear-fill"></i>
                                              </button>
                                            )
                                          }
                                          <button
                                            className="btn btn-outline-danger btn-round"
                                            type="button"
                                            title="Delete Card Template"
                                            data-bs-toggle="modal"
                                            data-bs-target="#confirmationModal"
                                            onClick={() => {
                                              if (isAllowed(appModules.CAMPAIGNS, READWRITE)) {
                                                handleActionSelection(cardTemplate)
                                              }
                                            }}
                                            disabled={!isAllowed(appModules.CAMPAIGNS, READWRITE)}
                                          >
                                            <TrashIcon />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                    ))
                                    )
                                  : (
                                    <tr>
                                      <td colSpan={8} className="text-center">
                                        No card templates available yet
                                      </td>
                                    </tr>
                                    )
                              )
                        }
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    isLoading={isLoadingCampaign}
                    metadata={{
                      limit: cardTemplatesMetadata.perPage,
                      total: cardTemplatesMetadata.total,
                      offset: ((cardTemplatesMetadata.page - 1) * (cardTemplatesMetadata.perPage))
                    }}
                    page={pageCardTemplate}
                    perPage={perPageCardTemplate}
                    handlePageChange={handleCardTemplatePageChange}
                    handleShowEntries={handleCardTemplateShowEntries}
                    module="campaigns-card-templates"
                  />
                  <DeleteConfirmationModal
                    isLoading = {isLoadingCardTemplates}
                    deleteById={deleteCardTemplate}
                    id={String(initialCardTemplate.id)}
                    name={<>card template for <span className="fw-bold">{`'${initialCardTemplate.name || ''}'`}</span>?</>}
                    token={String(token)}
                    autoDismiss={false}
                  />
                  <div className="modal fade" id="actionConfirmationModal" tabIndex={-1} aria-labelledby={'actionConfirmationModalLabel'} aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header text-center">
                          <h5 className="modal-title" id={'actionConfirmationModalLabel'}>
                            <i className="bi bi-gear-fill me-2"></i>Confirm Default Card Template
                          </h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {isLoadingCampaign && <Progress />}
                        <div className="modal-body">
                          <p>
                            Are you sure you want to set <span className="fw-bold">{`'${initialCardTemplate.name ?? '---'}'`}</span> as the default template for <span className="fw-bold">{`${campaign?.name ?? '---'}`}</span> campaign?
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              if (token && campaignId && isAllowed(appModules.CAMPAIGNS, READWRITE)) {
                                const controller = new AbortController()
                                const signal = controller.signal
                                const cardSetting: Partial<CardSetting> = {
                                  defaultBack: initialCardTemplate.back,
                                  defaultFront: initialCardTemplate.front
                                }
                                dispatch(createCampaignCardSetting({ id: campaignId, token, cardSetting, signal }))
                              }
                            }}
                            disabled={isLoadingCampaign}
                            data-bs-dismiss="modal"
                            aria-label="Save"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              <div className="row mt-4">
                {
                  (role === userRoles.ADMIN || campaign?.cardSetting?.isFrontSelectable)
                    ? (
                        !activeFront &&
                        (<div className="col">
                          <p className="text-primary fw-semibold">Select a new template to get started or continue where you left off from the saved card templates.</p>
                        </div>)
                      )
                    : (<div className="col">
                        <p className="text-primary fw-semibold">Template selection has been disabled.</p>
                      </div>)
                }
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setToggleRender(!toggleRender)
                      const triggerEl = document.querySelector('#canvasTab button[data-bs-target="#message-editor"]')
                      if (triggerEl) {
                        const tab = new Tab(triggerEl)
                        tab.show()
                      }
                    }}
                    disabled={activeFront === null}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="message-editor"
              role="tabpanel"
              aria-labelledby="message-editor-tab"
              tabIndex={0}
            >
              <div className="mt-2">
                <ul className="nav nav-pills mt-4 border p-1 rounded" id="messageEditorTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button onClick={() => setSide('front')} className="nav-link active" id="front-tab" data-bs-toggle="tab" data-bs-target="#front-tab-pane" type="button" role="tab" aria-controls="front-tab-pane" aria-selected="true">
                      <i className="bi bi-image"></i>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      onClick={() => {
                        setSide('back')
                        setToggleRender(!toggleRender)
                      }}
                      className="nav-link" id="back-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#back-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="back-tab-pane" aria-selected="false"
                    >
                      <i className="bi bi-card-text"></i>
                    </button>
                  </li>
                  {side === 'back' && <li className="nav-item ms-2" role="presentation">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      title="Change Orientation"
                      disabled={!(role === userRoles.ADMIN || campaign?.cardSetting?.isRotationEnabled)}
                      onClick={() => {
                        setBackOrientation(backOrientation === 'portrait' ? 'landscape' : 'portrait')
                      }}
                    >
                      {
                        (backOrientation === 'landscape')
                          ? <i className="bi bi-arrow-counterclockwise"></i>
                          : <i className="bi bi-arrow-clockwise"></i>
                      }
                      {
                        (backOrientation === 'landscape')
                          ? <i className="bi bi-phone-landscape"></i>
                          : <i className="bi bi-phone"></i>
                      }
                    </button>
                  </li>}
                </ul>
                <div className="tab-content mt-2 overflow-auto" id="messageEditorTabContent">
                  <div className="tab-pane fade show active" id="front-tab-pane" role="tabpanel" aria-labelledby="front-tab" tabIndex={0}>
                    <h5>Front Side</h5>
                    {activeFront
                      ? <ImageEditor
                          image={image}
                          stageHeight={imageStageHeight}
                          stageWidth={imageStageWidth}
                          stageRef={imageStageRef}
                          orientation={frontOrientation}
                        />
                      : (
                        <div className="border rounded d-flex justify-content-center p-5 text-center">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="currentColor" className="bi bi-card-image text-secondary" viewBox="0 0 16 16">
                              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                              <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
                            </svg>
                            <p>Front side not selected</p>
                          </div>
                        </div>
                        )}
                  </div>
                  <div className="tab-pane fade" id="back-tab-pane" role="tabpanel" aria-labelledby="back-tab" tabIndex={0}>
                    <h5>Edit Back Message {!(role === userRoles.ADMIN || campaign?.cardSetting?.isBackEditable) && <span className="text-danger">(Has been disabled by the admin)</span>}</h5>
                    <RichTextEditor
                      ref={quillRef}
                      readOnly={!(role === userRoles.ADMIN || campaign?.cardSetting?.isBackEditable)}
                      textContent={textContent}
                      onTextChange={setTextContent}
                      documentStageHeight={documentStageHeight}
                      documentStageWidth={documentStageWidth}
                      barcodeValue={getBarcode(campaign?.cardSetting).barcodeValue}
                      barcodeFormat={getBarcode(campaign?.cardSetting).barcodeType}
                      isLoading={isLoadingCampaignCardTemplates || isLoadingCampaign || isExporting}
                    />
                  </div>
                  <div
                    className="d-flex flex-row justify-content-between my-3 px-1"
                    style={{ width: (side === 'back' ? documentStageWidth : imageStageWidth + 28) }}
                  >
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => {
                          handleFrontPdfDownload()
                        }}
                        disabled={isExporting}
                      >
                        Export Front
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary ms-2"
                        onClick={() => {
                          handleBackPdfDownload()
                        }}
                        disabled={isExporting}
                      >
                        Export Back
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          const triggerEl = document.querySelector('#canvasTab button[data-bs-target="#drafts"]')
                          if (triggerEl) {
                            const tab = new Tab(triggerEl)
                            tab.show()
                          }
                        }}
                      >
                        Back
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary ms-2"
                        onClick={() => {
                          if (token && campaignId && isAllowed(appModules.CAMPAIGNS, READWRITE)) {
                            const controller = new AbortController()
                            const signal = controller.signal
                            if (initialCardTemplate.id === defaultCardTemplate?.id) {
                              const cardSetting: Partial<CardSetting> = {
                                defaultBack: textContent,
                                defaultFront: activeFront || undefined
                              }
                              dispatch(createCampaignCardSetting({ id: campaignId, token, cardSetting, signal }))
                            } else {
                              const cardTemplate = {
                                name: activeTemplate?.name || initialCardTemplate.name,
                                description: `Card Template for ${campaign?.name}`,
                                front: activeFront || undefined,
                                back: textContent,
                                frontOrientation,
                                backOrientation,
                                isDraft: true
                              }
                              dispatch(createCampaignCardTemplate({ id: campaignId, token, cardTemplate, signal }))
                            }
                          }
                        }}
                      >
                        {
                          initialCardTemplate.id === defaultCardTemplate?.id ? 'Save Default' : 'Save Draft'
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Canvas
