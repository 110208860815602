import React from 'react'
import { Formik } from 'formik'
import { number, object, date, string } from 'yup'
import dayjs from 'dayjs'
import Calendar from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useAppDispatch } from '../../store/hooks'
import { createCampaignQuota, editCampaignQuota } from '../../store/reducers/api/campaignQuotaReducer'
import { Quota } from '../../types'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import { CAMPAIGN_QUOTA_CREATION_SUCCESS_MESSAGE, CAMPAIGN_QUOTA_UPDATE_SUCCESS_MESSAGE } from '../../constants/messages'

interface QuotaEditorProps {
  isEditMode: boolean
  token: string
  campaignId: string
  campaignQuota: Quota | null
  handleQuotaRefresh: () => void
  isLoading: boolean
}

const QuotaEditor = ({
  handleQuotaRefresh,
  isEditMode,
  token,
  campaignId,
  campaignQuota,
  isLoading
}: QuotaEditorProps) => {
  const quotaSchema = object().shape({
    orderedDate: date().typeError('Ordered Date is required').label('Ordered Date').required(),
    orderedQuota: number().min(1).label('Ordered Quota').required(),
    orderId: string().nullable().label('Order ID').uppercase().required()
  })

  const dispatch = useAppDispatch()

  const initialValues = isEditMode
    ? {
        orderedDate: campaignQuota?.orderedDate
          ? new Date(campaignQuota?.orderedDate)
          : null,
        orderedQuota: campaignQuota?.orderedQuota || 0,
        orderId: campaignQuota?.orderId || null
      }
    : {
        orderedDate: null,
        orderedQuota: 0,
        orderId: null
      }

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={quotaSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (isEditMode) {
            if (
              campaignQuota &&
              campaignQuota?.id &&
              token &&
              values.orderedDate
            ) {
              const updatedCampaignQuota = {
                orderedQuota: values.orderedQuota,
                orderedDate: values.orderedDate.toISOString(),
                orderId: values.orderId
              }
              dispatch(
                editCampaignQuota({
                  token,
                  campaignQuota: updatedCampaignQuota,
                  campaignQuotaId: campaignQuota?.id
                })
              )
                .unwrap()
                .then(() => {
                  const payload = {
                    title: 'Success',
                    message: CAMPAIGN_QUOTA_UPDATE_SUCCESS_MESSAGE,
                    isVisible: true,
                    timestamp: dayjs().format('LT'),
                    type: 'success'
                  }
                  dismissModal('createQuotaModal')
                  dispatch(setToast(payload))
                  handleQuotaRefresh()
                })
                .catch((error: any) => {
                  if (error.errors.message) {
                    const payload = {
                      title: 'Error',
                      message: `Failed to edit quota: ${error.errors.message}`,
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'danger'
                    }
                    dispatch(setToast(payload))
                  } else {
                    const payload = {
                      title: 'Error',
                      message: `Failed to edit quota: ${error.message}`,
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'danger'
                    }
                    dispatch(setToast(payload))
                  }
                })
            }
          } else {
            if (token && campaignId) {
              if (values.orderedDate) {
                const campaignQuota = {
                  orderedQuota: values.orderedQuota,
                  orderedDate: values.orderedDate.toISOString(),
                  orderId: values.orderId
                }
                dispatch(createCampaignQuota({ token, campaignQuota, campaignId }))
                  .unwrap()
                  .then(() => {
                    const payload = {
                      title: 'Success',
                      message: CAMPAIGN_QUOTA_CREATION_SUCCESS_MESSAGE,
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'success'
                    }
                    dismissModal('createQuotaModal')
                    dispatch(setToast(payload))
                    handleQuotaRefresh()
                    actions.resetForm()
                  })
                  .catch((error: any) => {
                    if (error.errors.message) {
                      const payload = {
                        title: 'Error',
                        message: `Failed to add quota: ${error.errors.message}`,
                        isVisible: true,
                        timestamp: dayjs().format('LT'),
                        type: 'danger'
                      }
                      dispatch(setToast(payload))
                    } else {
                      const payload = {
                        title: 'Error',
                        message: `Failed to edit quota: ${error.message}`,
                        isVisible: true,
                        timestamp: dayjs().format('LT'),
                        type: 'danger'
                      }
                      dispatch(setToast(payload))
                    }
                  })
              }
            }
          }

          actions.setSubmitting(false)
        }}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label className="form-label" htmlFor="orderId">Order ID</label>
                  <input
                    type="text"
                    id="orderId"
                    name="orderId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${errors.orderId && touched.orderId ? 'is-invalid' : ''}`}
                    value={values.orderId || ''}
                    autoComplete="off"
                  />
                  <div className="invalid-feedback">
                    {errors.orderId}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="orderedDate">Ordered Date</label>
                  <div className={`${errors.orderedDate && touched.orderedDate ? 'is-invalid' : ''}`}>
                    <Calendar
                      id="orderedDate"
                      className={`form-control ${errors.orderedDate && touched.orderedDate ? 'is-invalid' : ''}`}
                      selected={values.orderedDate}
                      onChange={(date) => setFieldValue('orderedDate', date)}
                      dateFormat={'dd/MM/yyyy'}
                    />
                  </div>
                  <div className="invalid-feedback">{errors.orderedDate}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="orderedQuota">Ordered Quota</label>
                  <input
                    type="number"
                    id="orderedQuota"
                    name="orderedQuota"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${errors.orderedQuota && touched.orderedQuota ? 'is-invalid' : ''}`}
                    value={values.orderedQuota}
                  />
                  <div className="invalid-feedback">{errors.orderedQuota}</div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-4"
                disabled={isLoading}
              >
                <i className="bi bi-save text-white"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default QuotaEditor
