const quotaThresholds = {
  danger: 0.75,
  warning: 0.5,
  success: 0
}

export const getQuotaClassName = (correctionQuota: number, quota: number, usedQuota: number) => {
  if (quota - (correctionQuota + usedQuota) < 0) {
    return 'text-bg-danger'
  }
  if (quota === 0) {
    return 'text-bg-secondary'
  }
  const percentage = (correctionQuota + usedQuota) / quota

  for (const [className, threshold] of Object.entries(quotaThresholds)) {
    if (percentage >= threshold) {
      return `text-bg-${className}`
    }
  }

  return ''
}
