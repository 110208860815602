import React, { useEffect, useState } from 'react'
import { date, object, string } from 'yup'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import Calendar from 'react-datepicker'
import { countriesObject } from '../../utils/countries'
import { Address, Salutation, ShoppingCartAddress, Title } from '../../types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import {
  setShoppingAddresses,
  resetShoppingAddresses
} from '../../store/reducers/shoppingCartReducer'
import { setToast } from '../../store/reducers/toastReducer'
import { isWeekday } from '../../utils/isWeekday'
import { DaysOfWeek } from '../../enums/daysOfTheWeek'
import { phoneValidationPattern } from '../../constants/regexPatterns'
import { getDateOfDispatchMinimumDate } from '../../utils/getDateOfDispatchMinimumDate'
import { getDateOfDispatchMinimumDays } from '../../utils/getDateOfDispatchMinimumDays'

interface ShoppingDeliveryAddressEditorProps {
  shippingAddress: Partial<ShoppingCartAddress> | null
  loadCostCenterOptionsDebounced: any
  setSelectedDeliveryAddress: React.Dispatch<
    React.SetStateAction<Partial<Address> | null>
  >
  setSelectedAddress: React.Dispatch<
    React.SetStateAction<Partial<ShoppingCartAddress> | null>
  >
  editMode: boolean
  isOneAddress: boolean
}
const ShoppingDeliveryAddressEditor = ({
  shippingAddress,
  loadCostCenterOptionsDebounced,
  setSelectedDeliveryAddress,
  setSelectedAddress,
  editMode,
  isOneAddress
}: ShoppingDeliveryAddressEditorProps) => {
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const titles = useAppSelector((state) => state.apiTitle.titles)
  const shoppingCartAddresses = useAppSelector(
    (state) => state.shoppingCart.addresses
  )
  const shoppingCartBundles = useAppSelector(
    (state) => state.shoppingCart.bundles
  )
  const shoppingCartLeftOverProducts = useAppSelector(
    (state) => state.shoppingCart.leftOverProducts
  )

  const countries = countriesObject.map((country) => ({
    value: country.country,
    label: country.country
  }))

  const dispatch = useAppDispatch()

  const [note, setNote] = useState('')
  const [shippingDate, setShippingDate] = useState<Date | null>(null)
  const [saveAddress, setSaveAddress] = useState<'no' | 'yes'>('no')

  const orderSchema = object({
    companyName: string(),
    note: string()
      .max(100, (value) => `Note must be at most ${value.max} characters`)
      .default(''),
    salutation: string(),
    title: string(),
    firstName: string()
      .required('First Name is required')
      .min(2, (value) => `First Name must be at least ${value.min} characters`)
      .max(32, (value) => `First Name must be at most ${value.max} characters`),
    lastName: string()
      .required('Last Name is required')
      .min(2, (value) => `Last Name must be at least ${value.min} characters`)
      .max(32, (value) => `Last Name must be at most ${value.max} characters`),
    email: string().email('Enter a valid email').required('Email is required'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string()
      .label('Country')
      .required('Country is required')
      .oneOf(countries.map((country) => country.value)),
    street: string().required('Street and House Number are required'),
    city: string().required('City is required'),
    zip: string().label('Zip Code').required(),
    shippingDate: date()
      .typeError('Shipping Date is required')
      .label('Shipping Date')
      .min(
        dayjs()
          .startOf('day')
          .add(getDateOfDispatchMinimumDays(), 'days')
          .toDate(),
        (value) =>
          `Shipping Date field must be later than ${dayjs(value.min).format(
            'YYYY-MM-DD HH:mm'
          )}`
      ),
    costCenter: string().label('Cost Center')
  })

  useEffect(() => {
    setShippingDate(
      shippingAddress?.shippingDate
        ? new Date(shippingAddress.shippingDate)
        : null
    )
    setNote(shippingAddress?.note ?? '')
  }, [shippingAddress])

  return (
    <div className="row">
      <div className="col">
        <Formik
          validationSchema={orderSchema}
          enableReinitialize
          initialValues={{
            companyName: shippingAddress?.companyName ?? '',
            salutation: shippingAddress?.salutation ?? '',
            title: shippingAddress?.title ?? '',
            firstName: shippingAddress?.firstName ?? '',
            lastName: shippingAddress?.lastName ?? '',
            email: shippingAddress?.email ?? '',
            phone: shippingAddress?.phone ?? '',
            street: shippingAddress?.street ?? '',
            zip: shippingAddress?.zip ?? '',
            city: shippingAddress?.city ?? '',
            addressAddition: shippingAddress?.addressAddition ?? '',
            country: shippingAddress?.country ?? '',
            note,
            shippingDate,
            costCenter: shippingAddress?.costCenter ?? '',
            saveAddress
          }}
          onSubmit={(values, actions) => {
            let message = 'Delivery address added'
            const foundAddress = shoppingCartAddresses.find(
              (address) => address.id === shippingAddress?.id
            )

            if (foundAddress === undefined && shippingAddress?.id) {
              const updatedAddress: ShoppingCartAddress = {
                ...values,
                id: shippingAddress.id,
                shippingDate: dayjs(values.shippingDate)
                  .set('hour', dayjs().hour())
                  .set('minute', dayjs().minute())
                  .add(15, 'minutes')
                  .format(),
                assignedBundleIds: shoppingCartBundles.map(
                  (bundle) => bundle.id
                ),
                assignedLeftOverProductIds: shoppingCartLeftOverProducts.map(
                  (product) => product.id
                ),
                assignedBulkProductsIds:
                  shippingAddress.assignedBulkProductsIds ?? [],
                vat: null,
                type: 'delivery'
              }
              const updatedAddresses = isOneAddress
                ? [updatedAddress]
                : [...shoppingCartAddresses, updatedAddress]
              const payload = {
                addresses: updatedAddresses
              }
              if (isOneAddress) {
                dispatch(resetShoppingAddresses())
              }
              dispatch(setShoppingAddresses(payload))
            } else {
              if (editMode) {
                message = 'Delivery address updated'
                const updatedAddress = {
                  ...foundAddress,
                  ...values,
                  shippingDate: dayjs(values.shippingDate)
                    .set('hour', dayjs().hour())
                    .set('minute', dayjs().minute())
                    .add(15, 'minutes')
                    .format(),
                  type: 'delivery'
                }
                const updatedAddresses = shoppingCartAddresses.map((address) =>
                  address.id === updatedAddress.id ? updatedAddress : address
                )
                const payload = {
                  addresses: updatedAddresses
                }
                dispatch(setShoppingAddresses(payload))
              } else {
                message = 'Delivery address already added'
              }
            }

            openModal('shoppingShippingDetailsModal')
            dismissModal('shoppingDeliveryAddressEditorModal')
            setSelectedAddress(null)

            const toastPayload = {
              title: 'Info',
              message,
              isVisible: true,
              timestamp: dayjs().format('LT'),
              type: 'info',
              delay: 2000
            }
            dispatch(setToast(toastPayload))
            actions.setSubmitting(false)
            actions.resetForm()
            setShippingDate(null)
            setNote('')
            setSaveAddress('no')
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label htmlFor="companyName" className="form-label">
                      Company Name
                    </label>
                    <input
                      onChange={(event) =>
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          companyName: event.target.value
                        }))
                      }
                      onBlur={handleBlur}
                      value={values.companyName}
                      type="text"
                      className={`form-control ${
                        errors.companyName && touched.companyName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="companyName"
                      name="companyName"
                      placeholder="Enter company name"
                      autoComplete="off"
                    />
                    <div
                      id="validationCompanyNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.companyName}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label htmlFor="note" className="form-label">
                      Order Note
                    </label>
                    <textarea
                      onChange={(event) => {
                        setNote(event.target.value)
                      }}
                      onBlur={handleBlur}
                      value={values.note}
                      className={`form-control ${
                        errors.note && touched.note ? 'is-invalid' : ''
                      }`}
                      id="note"
                      name="note"
                      placeholder="Enter order note"
                      autoComplete="off"
                    />
                    <div
                      id="validationOrderNoteFeedback"
                      className="invalid-feedback"
                    >
                      {errors.note}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="salutation" className="form-label">
                      Salutation
                    </label>
                    <div className="input-group">
                      <select
                        aria-label="Salutation"
                        onChange={(event) =>
                          setSelectedDeliveryAddress((shippingAddress) => ({
                            ...shippingAddress,
                            salutation: event.target.value
                          }))
                        }
                        onBlur={handleBlur}
                        value={values.salutation}
                        className={`form-select ${
                          errors.salutation && touched.salutation
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="salutation"
                        autoComplete="honorific-prefix"
                      >
                        <option value="">Select Salutation</option>
                        {salutations.map(
                          (salutation: Salutation, index: number) => (
                            <option key={index}>{salutation.name}</option>
                          )
                        )}
                      </select>
                    </div>
                    <div
                      id="validationSalutationFeedback"
                      className="invalid-feedback"
                    >
                      {errors.salutation}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Title
                    </label>
                    <div className="input-group">
                      <select
                        aria-label="Title"
                        onChange={(event) =>
                          setSelectedDeliveryAddress((shippingAddress) => ({
                            ...shippingAddress,
                            title: event.target.value
                          }))
                        }
                        onBlur={handleBlur}
                        value={values.title}
                        className={`form-select ${
                          errors.title && touched.title
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="title"
                        autoComplete="honorific-prefix"
                      >
                        <option value="">Select Title</option>
                        {titles.map(
                          (title: Title, index: number) => (
                            <option key={index}>{title.name}</option>
                          )
                        )}
                      </select>
                    </div>
                    <div
                      id="validationTitleFeedback"
                      className="invalid-feedback"
                    >
                      {errors.title}
                    </div>
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      onChange={(event) =>
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          firstName: event.target.value
                        }))
                      }
                      onBlur={handleBlur}
                      value={values.firstName}
                      type="text"
                      className={`form-control ${
                        errors.firstName && touched.firstName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="firstName"
                      placeholder="Enter first name"
                      autoComplete="given-name"
                    />
                    <div
                      id="validationFirstNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.firstName}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      onChange={(event) =>
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          lastName: event.target.value
                        }))
                      }
                      onBlur={handleBlur}
                      value={values.lastName}
                      type="text"
                      className={`form-control ${
                        errors.lastName && touched.lastName ? 'is-invalid' : ''
                      }`}
                      id="lastName"
                      placeholder="Enter last name"
                      autoComplete="family-name"
                    />
                    <div
                      id="validationLastNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.lastName}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="orderEmail" className="form-label">
                      Email Address
                    </label>
                    <input
                      onChange={(event) =>
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          email: event.target.value
                        }))
                      }
                      onBlur={handleBlur}
                      type="email"
                      className={`form-control ${
                        errors.email && touched.email ? 'is-invalid' : ''
                      }`}
                      value={values.email}
                      id="orderEmail"
                      name="email"
                      placeholder="Enter Email Address"
                      autoComplete="email"
                    />
                    <div
                      id="validationEmailFeedback"
                      className="invalid-feedback"
                    >
                      {errors.email}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      onChange={(event) =>
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          phone: event.target.value
                        }))
                      }
                      onBlur={handleBlur}
                      value={values.phone}
                      type="text"
                      className={`form-control ${
                        errors.phone && touched.phone ? 'is-invalid' : ''
                      }`}
                      id="phone"
                      placeholder="Enter Phone Number"
                      autoComplete="tel"
                    />
                    <div
                      id="validationPhoneFeedback"
                      className="invalid-feedback"
                    >
                      {errors.phone}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="orderStreet" className="form-label">
                      Street and House Number
                    </label>
                    <input
                      onChange={(event) =>
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          street: event.target.value
                        }))
                      }
                      onBlur={handleBlur}
                      value={values.street}
                      type="text"
                      className={`form-control ${
                        errors.street && touched.street ? 'is-invalid' : ''
                      }`}
                      id="orderStreet"
                      name="street"
                      placeholder="Enter street and House Number"
                      autoComplete="street-address"
                    />
                    <div
                      id="validationOrderStreetFeedback"
                      className="invalid-feedback"
                    >
                      {errors.street}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="orderZip" className="form-label">
                      Zip
                    </label>
                    <input
                      onChange={(event) =>
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          zip: event.target.value
                        }))
                      }
                      onBlur={handleBlur}
                      value={values.zip}
                      type="text"
                      className={`form-control ${
                        errors.zip && touched.zip ? 'is-invalid' : ''
                      }`}
                      id="orderZip"
                      name="zip"
                      placeholder="Enter zip"
                      autoComplete="postal-code"
                    />
                    <div
                      id="validationOrderZipFeedback"
                      className="invalid-feedback"
                    >
                      {errors.zip}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="orderCity" className="form-label">
                      City
                    </label>
                    <input
                      onChange={(event) =>
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          city: event.target.value
                        }))
                      }
                      onBlur={handleBlur}
                      type="text"
                      className={`form-control ${
                        errors.city && touched.city ? 'is-invalid' : ''
                      }`}
                      value={values.city}
                      id="orderCity"
                      name="city"
                      placeholder="Enter city"
                      autoComplete="address-level2"
                    />
                    <div
                      id="validationOrderCityFeedback"
                      className="invalid-feedback"
                    >
                      {errors.city}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="orderAddressAddition"
                      className="form-label"
                    >
                      Address Addition
                    </label>
                    <input
                      onChange={(event) =>
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          addressAddition: event.target.value
                        }))
                      }
                      onBlur={handleBlur}
                      value={values.addressAddition}
                      type="text"
                      className={`form-control ${
                        errors.addressAddition && touched.addressAddition
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="orderAddressAddition"
                      name="addressAddition"
                      placeholder="Enter Address Addition"
                      autoComplete="on"
                    />
                    <div
                      id="validationOrderAddressAdditionFeedback"
                      className="invalid-feedback"
                    >
                      {errors.addressAddition}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <Select
                      className={`${
                        touched.country && errors.country ? 'is-invalid' : ''
                      }`}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor:
                            errors.country && touched.country
                              ? '#dc3545'
                              : provided.borderColor,
                          '&:hover': {
                            boxShadow:
                              errors.country && touched.country
                                ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)'
                                : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                            borderColor:
                              errors.country && touched.country
                                ? '#dc3545'
                                : '#86b7fe'
                          }
                        })
                      }}
                      isClearable
                      inputId="country"
                      name="country"
                      aria-label="Country"
                      options={countries}
                      onChange={(selectedOption) => {
                        const selectedCountry = selectedOption?.value ?? ''
                        setFieldValue('country', selectedCountry)
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          country: selectedCountry
                        }))
                      }}
                      onBlur={handleBlur}
                      value={countries.find(
                        (country) => country.value === values.country
                      )}
                    />
                    <div
                      id="validationOrderCountryFeedback"
                      className="invalid-feedback"
                    >
                      {errors.country}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="shippingDate" className="form-label">
                      Shipping Date
                    </label>
                    <div
                      className={`${
                        errors.shippingDate && touched.shippingDate
                          ? 'is-invalid'
                          : ''
                      }`}
                    >
                      <Calendar
                        id="shippingDate"
                        name="shippingDate"
                        onChange={(date: Date) => {
                          setShippingDate(date)
                        }}
                        selected={values.shippingDate}
                        className={`form-control ${
                          errors.shippingDate && touched.shippingDate
                            ? 'is-invalid'
                            : ''
                        }`}
                        minDate={getDateOfDispatchMinimumDate()}
                        dateFormat={'dd/MM/yyyy'}
                        autoComplete={'off'}
                        filterDate={isWeekday}
                        calendarStartDay={DaysOfWeek.Monday}
                        placeholderText="Select a weekday"
                      />
                    </div>
                    <div
                      id="validationShippingDateFeedback"
                      className="invalid-feedback"
                    >
                      {errors.shippingDate}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="deliveryCostCenter" className="form-label">
                      Cost Center
                    </label>
                    <AsyncSelect
                      inputId="deliveryCostCenter"
                      cacheOptions
                      loadOptions={loadCostCenterOptionsDebounced}
                      defaultOptions
                      onChange={(selectedOption: any) => {
                        setFieldValue('costCenter', selectedOption.value)
                        setSelectedDeliveryAddress((shippingAddress) => ({
                          ...shippingAddress,
                          costCenter: selectedOption.value
                        }))
                      }}
                      placeholder="Select Cost Center"
                      className={`${
                        errors.costCenter && touched.costCenter
                          ? 'is-invalid'
                          : ''
                      }`}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor:
                            errors.costCenter && touched.costCenter
                              ? '#dc3545'
                              : provided.borderColor,
                          '&:hover': {
                            boxShadow:
                              errors.costCenter && touched.costCenter
                                ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)'
                                : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                            borderColor:
                              errors.costCenter && touched.costCenter
                                ? '#dc3545'
                                : '#86b7fe'
                          }
                        })
                      }}
                      value={{
                        value: values.costCenter,
                        label: values.costCenter
                      }}
                    />
                    <div
                      id="validationCostCenterFeedback"
                      className="invalid-feedback"
                    >
                      {errors.costCenter}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <p className="mt-2 mb-1">Save the address information</p>
                    <div
                      className={`form-check form-check-inline ${
                        errors.saveAddress ? 'is-invalid' : ''
                      }`}
                    >
                      <input
                        className={`form-check-input ${
                          errors.saveAddress ? 'is-invalid' : ''
                        }`}
                        type="radio"
                        name="saveAddress"
                        id="inlineDeliveryAddressRadioYes"
                        onChange={() => {
                          setSaveAddress('yes')
                        }}
                        value="yes"
                        autoComplete="off"
                        checked={values.saveAddress === 'yes'}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineDeliveryAddressRadioYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div
                      className={`form-check form-check-inline ${
                        errors.saveAddress ? 'is-invalid' : ''
                      }`}
                    >
                      <input
                        className={`form-check-input ${
                          errors.saveAddress ? 'is-invalid' : ''
                        }`}
                        type="radio"
                        name="saveAddress"
                        id="inlineDeliveryAddressRadioNo"
                        onChange={() => {
                          setSaveAddress('no')
                        }}
                        value="no"
                        autoComplete="off"
                        checked={values.saveAddress === 'no'}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineDeliveryAddressRadioNo"
                      >
                        No
                      </label>
                    </div>
                    <div
                      id="validationSaveAddressFeedback"
                      className="invalid-feedback"
                    >
                      {errors.saveAddress}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-top">
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-secondary mt-3"
                    onClick={() => {
                      if (editMode) {
                        openModal('shoppingShippingDetailsModal')
                        dismissModal('shoppingDeliveryAddressEditorModal')
                      } else {
                        openModal('shoppingDeliveryAddressPickerModal')
                        dismissModal('shoppingDeliveryAddressEditorModal')
                      }
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary ms-2 mt-3"
                    disabled={isSubmitting}
                  >
                    {editMode ? 'Update' : 'Add'} Address
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ShoppingDeliveryAddressEditor
