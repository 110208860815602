import React, { useState } from 'react'
import { Formik } from 'formik'
import { number, object, string, array } from 'yup'
import dayjs from 'dayjs'
import CreatableSelect from 'react-select/creatable'
import 'react-datepicker/dist/react-datepicker.css'
import { useAppDispatch } from '../../store/hooks'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import { CAMPAIGN_QUOTA_NOTIFICATION_CREATION_SUCCESS_MESSAGE, CAMPAIGN_QUOTA_NOTIFICATION_UPDATE_SUCCESS_MESSAGE } from '../../constants/messages'
import { createCampaignQuotaNotification, updateCampaignQuotaNotification } from '../../store/reducers/api/campaignQuotaNotificationReducer'
import { QuotaNotification } from '../../types'

interface QuotaNotificationEditorProps {
  token: string
  campaignId: string
  handleQuotaNotificationRefresh: () => void
  isLoading: boolean
  selectedQuotaNotification: QuotaNotification | null
  editModeQuotaNotification: boolean
}

const QuotaNotificationEditor = ({
  handleQuotaNotificationRefresh,
  token,
  campaignId,
  isLoading,
  selectedQuotaNotification,
  editModeQuotaNotification
}: QuotaNotificationEditorProps) => {
  const quotaNotificationSchema = object().shape({
    threshold: number().min(1).max(100).label('Threshold').required('Threshold is required'),
    recipients: array()
      .label('Recipient Emails')
      .of(string().email('Recipient Emails must be valid emails '))
      .min(1, 'Recipient Emails field must have at least 1 email')
  })

  const dispatch = useAppDispatch()
  const [recipientInput, setRecipientInput] = useState<string>('')

  const initialValues = {
    threshold: editModeQuotaNotification && selectedQuotaNotification ? selectedQuotaNotification.threshold : 0,
    recipients: editModeQuotaNotification && selectedQuotaNotification ? selectedQuotaNotification.recipients : []
  }

  const components = {
    DropdownIndicator: null
  }

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={quotaNotificationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const campaignQuotaNotification = {
            threshold: values.threshold,
            recipients: values.recipients
          }
          if (!editModeQuotaNotification) {
            dispatch(createCampaignQuotaNotification({ token, campaignId, campaignQuotaNotification }))
              .unwrap()
              .then(() => {
                const payload = {
                  title: 'Success',
                  message: CAMPAIGN_QUOTA_NOTIFICATION_CREATION_SUCCESS_MESSAGE,
                  isVisible: true,
                  timestamp: dayjs().format('LT'),
                  type: 'success'
                }
                dismissModal('createQuotaNotificationModal')
                dispatch(setToast(payload))
                handleQuotaNotificationRefresh()
                actions.resetForm()
                actions.setFieldValue('recipients', [])
              })
              .catch(() => {
                const payload = {
                  title: 'Error',
                  message: 'Failed to add Quota notification',
                  isVisible: true,
                  timestamp: dayjs().format('LT'),
                  type: 'danger'
                }
                dispatch(setToast(payload))
              })
          } else {
            if (selectedQuotaNotification?.id) {
              dispatch(updateCampaignQuotaNotification({ token, campaignQuotaNotificationId: selectedQuotaNotification.id, campaignQuotaNotification }))
                .unwrap()
                .then(() => {
                  const payload = {
                    title: 'Success',
                    message: CAMPAIGN_QUOTA_NOTIFICATION_UPDATE_SUCCESS_MESSAGE,
                    isVisible: true,
                    timestamp: dayjs().format('LT'),
                    type: 'success'
                  }
                  dismissModal('createQuotaNotificationModal')
                  dispatch(setToast(payload))
                  handleQuotaNotificationRefresh()
                })
                .catch(() => {
                  const payload = {
                    title: 'Error',
                    message: 'Failed to add Quota notification',
                    isVisible: true,
                    timestamp: dayjs().format('LT'),
                    type: 'danger'
                  }
                  dispatch(setToast(payload))
                })
            }
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
            <div className="">
              <div className="mb-3">
                <label className="form-label" htmlFor="quotaNotificationThreshold">Notify me when remaining quota is below</label>
                <div className="input-group">
                  <input
                    type="number"
                    id="quotaNotificationThreshold"
                    name="threshold"
                    onChange={(e) => {
                      if (Number(e.target.value) <= 100 && Number(e.target.value) >= 0) {
                        handleChange(e)
                      }
                    }}
                    onBlur={handleBlur}
                    className={`form-control ${errors.threshold && touched.threshold ? 'is-invalid' : ''}`}
                    value={values.threshold}
                  />
                  <span className="input-group-text">%</span>
                </div>
                {errors.threshold && touched.threshold && (
                  <div className="invalid-feedback d-block">{errors.threshold}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="quotaNotificationRecipients">Recipient Emails</label>
                <CreatableSelect
                  inputId="quotaNotificationRecipients"
                  name="recipients"
                  className={`${
                    touched.recipients &&
                    errors.recipients
                      ? 'is-invalid'
                      : ''
                  }`}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: (errors.recipients && touched.recipients) ? '#dc3545' : provided.borderColor,
                      '&:hover': {
                        borderColor: (errors.recipients && touched.recipients) ? '#dc3545' : provided.borderColor
                      }
                    })
                  }}
                  components={components}
                  inputValue={recipientInput}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => {
                    setFieldValue('recipients', newValue.map((option) => option))
                  }}
                  onInputChange={(newValue) => setRecipientInput(newValue)}
                  onKeyDown={(event) => {
                    if (!recipientInput) return
                    switch (event.key) {
                      case 'Enter':
                      case 'Tab':
                        setRecipientInput('')
                        setFieldValue('recipients', Array.from(new Set([...values.recipients, recipientInput])))
                        event.preventDefault()
                    }
                  }}
                  placeholder="Type an email and press enter..."
                  value={values.recipients}
                  getOptionLabel={(email) => email}
                  getOptionValue={(email) => email}
                />
                <div
                  id="validationQuotaNotificationRecipientsFeedback"
                  className="invalid-feedback"
                >
                  {errors.recipients}
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-4"
                disabled={isLoading}
              >
                <i className="bi bi-save text-white"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default QuotaNotificationEditor
