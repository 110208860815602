import axios from 'axios'
import { Quota } from '../../types'
const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export const getAllCampaignQuotas = async ({
  token,
  perPage,
  page,
  campaignId
}: {
  token: string
  perPage: number
  page: number
  campaignId: string
}) => {
  const response = await apiClient.get(`/campaigns/${campaignId}/quotas`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      limit: perPage,
      page
    }
  })
  return response.data
}

export const createCampaignQuota = async ({
  token,
  campaignQuota,
  campaignId
}: {
  token: string
  campaignId: string
  campaignQuota: Pick<Quota, 'orderedQuota' | 'orderedDate' | 'orderId'>
}) => {
  const response = await apiClient.post(
    `/campaigns/${campaignId}/quotas`,
    { campaignQuota },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}
export const editCampaignQuota = async ({
  token,
  campaignQuota,
  campaignQuotaId
}: {
  token: string
  campaignQuotaId: string
  campaignQuota: Pick<Quota, 'orderedQuota' | 'orderedDate' | 'orderId'>
}) => {
  const response = await apiClient.put(
    `/campaign-quotas/${campaignQuotaId}`,
    { campaignQuota },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export const deleteCampaignQuota = async ({
  token,
  campaignQuotaId
}: {
  token: string
  campaignQuotaId: string
}) => {
  const response = await apiClient.delete(
    `/campaign-quotas/${campaignQuotaId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export const createCampaignQuotaNotification = async ({
  token,
  campaignId,
  campaignQuotaNotification
}: {
  token: string
  campaignId: string,
  campaignQuotaNotification:{
    threshold: number,
    recipients:string[]
  }
}) => {
  const response = await apiClient.post(
    `/campaigns/${campaignId}/quota-notifications`,
    { campaignQuotaNotification },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export const getCampaignQuotaNotifications = async ({
  token,
  campaignId,
  perPage,
  page
}: {
  token: string
  campaignId: string
  perPage: number,
  page: number,
}) => {
  const response = await apiClient.get(
    `/campaigns/${campaignId}/quota-notifications`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        limit: perPage,
        page
      }
    }
  )
  return response.data
}
export const updateCampaignQuotaNotification = async ({
  token,
  campaignQuotaNotificationId,
  campaignQuotaNotification
}: {
  token: string
  campaignQuotaNotificationId: string,
  campaignQuotaNotification: {
    threshold: number
    recipients: string[]
  }
}) => {
  const response = await apiClient.put(
    `/campaign-quota-notifications/${campaignQuotaNotificationId}`,
    { campaignQuotaNotification },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}
export const deleteCampaignQuotaNotification = async ({
  token,
  campaignQuotaNotificationId
}: {
  token: string
  campaignQuotaNotificationId: string
}) => {
  const response = await apiClient.delete(
    `/campaign-quota-notifications/${campaignQuotaNotificationId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}
export default { getAllCampaignQuotas, createCampaignQuota, editCampaignQuota, deleteCampaignQuota, createCampaignQuotaNotification, getCampaignQuotaNotifications, updateCampaignQuotaNotification, deleteCampaignQuotaNotification }
