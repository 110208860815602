import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { Title, User } from '../../../types'
import Select from 'react-select'
import { countriesObject } from '../../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { updateUserById } from '../../../store/reducers/api/usersReducer'
import { fireConfetti } from '../../animations/confetti'
import { phoneValidationPattern } from '../../../constants/regexPatterns'
import { getAllTitles } from '../../../store/reducers/api/titleReducer'

interface ProfileUpdateProps {
  profile: User | null
  token: string | null
  isLoading: boolean
  setMode: Function
  setTitle: Function
  editBillingOrDeliveryAddress: boolean
}

const ProfileUpdater = ({ profile, isLoading, setMode, setTitle, editBillingOrDeliveryAddress, token }: ProfileUpdateProps) => {
  const titles = useAppSelector((state) => state.apiTitle.titles)
  const isLoadingTitles = useAppSelector((state) => state.apiTitle.isLoading)
  const onboardingProgress = useAppSelector((state) => state.onboarding.onboardingProgress)

  const personalAddress = profile?.addresses && profile?.addresses.find((address) => address.type === 'delivery' || address.type === null)

  const userId = profile?.id
  const countries = countriesObject.map(country => ({ value: country.country, label: country.country }))

  const dispatch = useAppDispatch()

  const profileSchema = object({
    title: string().nullable(),
    firstName: string()
      .required('First Name is required')
      .min(2, 'Enter a valid first name')
      .max(32, 'First Name is too long'),
    lastName: string()
      .required('Last Name is required')
      .min(2, 'Enter a valid last name')
      .max(32, 'Last Name is too long'),
    username: string()
      .nullable()
      .min(2, 'Username is too short')
      .max(32, 'Username is too long')
      .matches(/^\S+$/, 'Enter a valid username'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string().oneOf(countries.map(country => country.value)),
    address: object({
      country: string().required('Country is required').oneOf(countries.map(country => country.value)),
      city: string()
        .required('City is required')
        .max(32, 'City name is too long'),
      street: string()
        .required('Street name and House Number are required')
        .max(64, 'Street name and House Number are too long'),
      zip: string()
        .required('Zip is required')
        .max(16, 'Zip is too long'),
      phone: string()
        .nullable()
        .matches(phoneValidationPattern, 'Enter a valid phone number'),
      addressAddition: string()
        .nullable()
        .max(255, 'Address Addition is too long')
    })
  })

  useEffect(() => {
    if (onboardingProgress === 100) {
      fireConfetti()
    }
  }, [onboardingProgress])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllTitles({ token, perPage: 20, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <div>
      <Formik
        validationSchema={profileSchema}
        initialValues={{
          title: profile?.title || '',
          firstName: profile?.firstName,
          lastName: profile?.lastName,
          username: profile?.username || '',
          phone: profile?.phone || '',
          address: {
            country: personalAddress?.country || '',
            city: personalAddress?.city || '',
            street: personalAddress?.street || '',
            zip: personalAddress?.zip || '',
            phone: personalAddress?.phone || '',
            addressAddition: personalAddress?.addressAddition || '',
            type: 'delivery',
            affiliation: 'personal'
          }
        }}
        onSubmit={(
          { title, firstName, lastName, username, phone, address },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal

          const user = {
            title: title || null,
            firstName,
            lastName,
            username: username || null,
            phone: phone || null,
            address
          }

          if (token && userId) {
            dispatch(
              updateUserById({
                id: userId,
                token,
                user,
                signal
              })
            )
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit} id="onboardingProfileForm">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="onboardingProfileTitle"
                      className="form-label"
                    >
                      Title
                    </label>
                    <div className="input-group">
                      <select
                        aria-label="Title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        className={`form-select ${
                          touched.title &&
                          errors.title
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="onboardingProfileTitle"
                        name="title"
                        autoComplete="on"
                        disabled={isLoadingTitles}
                      >
                        <option value="">Select Title</option>
                        {titles.map(
                          (title: Title, index: number) => (
                            <option key={index}>
                              {title.name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div
                      id="validationTitleFeedback"
                      className="invalid-feedback"
                    >
                      {errors.title}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="onboardingProfileFirstName"
                      className="form-label"
                    >
                      First Name
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      type="text"
                      className={`form-control ${
                        touched.firstName &&
                        errors.firstName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingProfileFirstName"
                      name="firstName"
                      placeholder="Enter first name"
                      autoComplete="on"
                    />
                    <div
                      id="validationFirstNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.firstName}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="onboardingProfileLastName"
                      className="form-label"
                    >
                      Last Name
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      type="text"
                      className={`form-control ${
                        touched.lastName &&
                        errors.lastName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingProfileLastName"
                      name="lastName"
                      placeholder="Enter last name"
                      autoComplete="on"
                    />
                    <div
                      id="validationLastNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.lastName}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="onboardingProfileEmail"
                      className="form-label"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={profile?.email || ''}
                      id="onboardingProfileEmail"
                      name="email"
                      placeholder="Email"
                      disabled
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="onboardingProfilePhone" className="form-label">
                      Phone
                    </label>
                    <input
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className={`form-control ${
                        touched.phone &&
                        errors.phone
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingProfilePhone"
                      name="phone"
                      placeholder="Phone"
                      autoComplete="on"
                    />
                    <div
                      id="validationPhoneFeedback"
                      className="invalid-feedback"
                    >
                      {errors.phone}
                    </div>
                  </div>
                </div>
              </div>

              <h6 className="mt-4 mb-3 text-capitalize">
                <i className="bi bi-pencil-square me-2"></i>
                Personal Delivery Address
              </h6>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="onboardingStreet"
                      className="form-label"
                    >
                      Street and House Number
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.street}
                      type="text"
                      className={`form-control ${
                        touched.address?.street &&
                        errors.address?.street
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingStreet"
                      name="address.street"
                      placeholder="Enter street and House Number"
                      autoComplete="on"
                    />
                    <div
                      id="validationAddressStreetFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.street}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="onboardingZip" className="form-label">
                      Zip
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.zip}
                      type="text"
                      className={`form-control ${
                        touched.address?.zip && errors.address?.zip
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingZip"
                      name="address.zip"
                      placeholder="Enter zip"
                      autoComplete="on"
                    />
                    <div
                      id="validationAddressZipFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.zip}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="onboardingCity" className="form-label">
                      City
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className={`form-control ${
                        touched.address?.city &&
                        errors.address?.city
                          ? 'is-invalid'
                          : ''
                      }`}
                      value={values.address.city}
                      id="onboardingCity"
                      name="address.city"
                      placeholder="Enter city"
                      autoComplete="on"
                    />
                    <div
                      id="validationAddressCityFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.city}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="onboardingAddressAddition"
                      className="form-label"
                    >
                      Address Addition
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.addressAddition}
                      type="text"
                      className={`form-control ${
                        touched.address?.addressAddition &&
                        errors.address?.addressAddition
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingAddressAddition"
                      name="address.addressAddition"
                      placeholder="Enter Address Addition"
                      autoComplete="on"
                    />
                    <div
                      id="validationAddressAddressAdditionFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.addressAddition}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="onboardingPhone" className="form-label">
                      Phone
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.phone}
                      type="text"
                      className={`form-control ${
                        touched.address?.phone &&
                        errors.address?.phone
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingPhone"
                      name="address.phone"
                      placeholder="Enter Phone Number"
                      autoComplete="on"
                    />
                    <div
                      id="validationAddressPhoneFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.phone}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="onboardingAddressCountry"
                      className="form-label"
                    >
                      Country
                    </label>
                    <Select
                      className={`${
                        touched.address?.country &&
                        errors.address?.country
                          ? 'is-invalid'
                          : ''
                      }`}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: (errors.address?.country && touched.address?.country) ? '#dc3545' : provided.borderColor,
                          '&:hover': {
                            borderColor: (errors.address?.country && touched.address?.country) ? '#dc3545' : provided.borderColor
                          }
                        })
                      }}
                      isClearable
                      inputId="onboardingAddressCountry"
                      name="address.country"
                      aria-label="Country"
                      options={countries}
                      getOptionLabel={(country) => `${country.value}`}
                      getOptionValue={(country) => String(country.value)}
                      onChange={(selectedOption) => {
                        const selectedCountry = selectedOption?.value ?? ''
                        setFieldValue('address.country', selectedCountry)
                      }}
                      onBlur={handleBlur}
                      value={countries.find((country) => country.value === values.address.country)}
                    />
                    <div
                      id="validationAddressCountryFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.country}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer mt-4">
              <button type="button"
                className="btn btn-secondary"
                onClick={() => {
                  if (editBillingOrDeliveryAddress) {
                    setMode('billingOrDeliveryAddress')
                    setTitle('Address Information')
                  } else {
                    setMode('assignOrCreateCompany')
                  }
                }}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  isSubmitting || isLoading
                }
              >
                Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ProfileUpdater
