import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import TitleService from '../../../services/api/TitleService'
import { ApiTitleState } from '../../../types'
import { TITLE_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiTitleState = {
  isLoading: false,
  error: null,
  title: null,
  titles: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getAllTitles = createAsyncThunk('api/titles/get', async ({ token, perPage, page, signal }: { token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await TitleService.getAllTitles(token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addTitle = createAsyncThunk('api/titles/add', async ({ token, title, signal }: { token: string, title: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await TitleService.addTitle(token, title, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editTitleById = createAsyncThunk('api/titles/edit', async ({ token, titleId, title, signal }: { token: string, titleId: any, title: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await TitleService.editTitleById(token, titleId, title, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteTitleById = createAsyncThunk('api/titles/delete', async ({ token, titleId, signal }: { token: string, titleId: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await TitleService.deleteTitleById(token, titleId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const titleSlice = createSlice({
  name: 'api/title',
  initialState,
  reducers: {
    resetTitleError: (state) => {
      state.error = null
    },
    resetTitleMessage: (state) => {
      state.message = null
    },
    resetTitleData: (state) => {
      state.error = null
      state.title = null
      state.titles = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTitles.pending, (state) => {
        state.titles = []
        state.isLoading = true
      })
      .addCase(getAllTitles.fulfilled, (state, action) => {
        state.isLoading = false
        state.titles = action.payload.titles
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllTitles.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addTitle.pending, (state) => {
        state.titles = []
        state.isLoading = true
      })
      .addCase(addTitle.fulfilled, (state, action) => {
        state.isLoading = false
        state.title = action.payload.title
        state.error = null
        state.message = null
      })
      .addCase(addTitle.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editTitleById.pending, (state) => {
        state.titles = []
        state.isLoading = true
      })
      .addCase(editTitleById.fulfilled, (state, action) => {
        state.isLoading = false
        state.title = action.payload.title
        state.error = null
        state.message = null
      })
      .addCase(editTitleById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteTitleById.pending, (state) => {
        state.titles = []
        state.isLoading = true
      })
      .addCase(deleteTitleById.fulfilled, (state, action) => {
        state.isLoading = false
        state.title = null
        state.error = null
        state.message = TITLE_DELETION_MESSAGE
      })
      .addCase(deleteTitleById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetTitleError, resetTitleMessage, resetTitleData } = titleSlice.actions

const { reducer } = titleSlice
export default reducer
