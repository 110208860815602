import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string, number } from 'yup'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { getMyProfile, resetProfileError } from '../../store/reducers/api/profileReducer'
import {
  resetUserData,
  resetUserMessage,
  sendVerifyCode,
  updateUserPasswordById,
  verifyEmail
} from '../../store/reducers/api/usersReducer'
import {
  logoutCurrentUser,
  setCurrentUser
} from '../../store/reducers/api/authReducer'
import Progress from '../../components/loaders/Progress'
import {
  ADDRESS_UPDATE_SUCCESS_MESSAGE,
  EMAIL_VERIFICATION_CONFIRMATION,
  PASSWORD_CHANGE_CONFIRMATION,
  USER_UPDATE_SUCCESS_MESSAGE,
  VERIFICATION_CODE_CONFIRMATION
} from '../../constants/messages'
import { resetToast, setToast } from '../../store/reducers/toastReducer'
import {
  getCompanyById
} from '../../store/reducers/api/companyReducer'
import * as userRoles from '../../constants/userRoles'
import {
  addSalutation, deleteASalutationById,
  editASalutationById, getAllSalutations
} from '../../store/reducers/api/salutationReducer'
import SalutationEditor from '../../components/Salutations/SalutationEditor'
import hasPermission from '../../utils/checkPermissions'
import * as appModules from '../../constants/appModules'
import ProfileEditor from '../../components/editors/ProfileEditor'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import TitleEditor from '../../components/Titles/TitleEditor'
import { addTitle, deleteTitleById, editTitleById, getAllTitles } from '../../store/reducers/api/titleReducer'

dayjs.extend(localizedFormat)

const Profile = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.profile.isLoading)
  const updatedUser = useAppSelector((state) => state.apiUsers.user)
  const isUpdating = useAppSelector((state) => state.apiUsers.isUpdating)

  const error = useAppSelector((state) => state.profile.error)
  const userError = useAppSelector((state) => state.apiUsers.error)
  const userMessage = useAppSelector((state) => state.apiUsers.message)
  const address = useAppSelector((state) => state.apiCompany.address)

  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const salutation = useAppSelector((state) => state.apiSalutation.salutation)
  const salutationMessage = useAppSelector((state) => state.apiSalutation.message)
  const isLoadingSalutations = useAppSelector((state) => state.apiSalutation.isLoading)

  const titles = useAppSelector((state) => state.apiTitle.titles)
  const title = useAppSelector((state) => state.apiTitle.title)
  const titleMessage = useAppSelector((state) => state.apiTitle.message)
  const isLoadingTitles = useAppSelector((state) => state.apiTitle.isLoading)

  const [selectedSalutation, setSelectedSalutation] = useState({
    id: '',
    name: ''
  })
  const [selectedTitle, setSelectedTitle] = useState({
    id: '',
    name: ''
  })

  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []

  const token = currentUser?.token
  const userId = profile?.id
  const companyId = profile?.company?.id
  const companyOwnerId = profile?.company?.owner?.id
  const role = profile?.role || userRoles.USER

  const isOwner = companyOwnerId && userId === companyOwnerId

  const dispatch = useAppDispatch()

  const verificationSchema = object({
    otp: number()
      .typeError('OTP must be a number')
      .positive('OTP must be a positive number')
      .integer('OTP must be an integer')
      .required('OTP is required')
  })

  const passwordSchema = object({
    currentPassword: string()
      .required('Current Password is required'),
    password: string()
      .required('New Password is required')
      .min(6, 'New Password must be at least 6 characters')
  })

  const verify = (email: string, otp: number) => {
    const controller = new AbortController()
    const signal = controller.signal
    const user = {
      email,
      otp
    }
    dispatch(resetUserData())
    if (userId && token) {
      dispatch(verifyEmail({ id: userId, token, user, signal }))
    }
  }

  useEffect(() => {
    if (userMessage && userMessage === EMAIL_VERIFICATION_CONFIRMATION) {
      dispatch(logoutCurrentUser())
    }

    if (userMessage && userMessage === PASSWORD_CHANGE_CONFIRMATION) {
      const payload = {
        title: 'Success',
        message: PASSWORD_CHANGE_CONFIRMATION,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))

      const timer = setTimeout(() => {
        dispatch(resetUserData())
        dispatch(resetProfileError())
        dispatch(resetToast())
        dispatch(logoutCurrentUser())
      }, 3000)

      return () => {
        clearTimeout(timer)
      }
    }

    if (userMessage && userMessage === VERIFICATION_CODE_CONFIRMATION) {
      const payload = {
        title: 'OTP request successful',
        message: userMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
    }

    if (userMessage && (userMessage === (USER_UPDATE_SUCCESS_MESSAGE) || userMessage === ADDRESS_UPDATE_SUCCESS_MESSAGE)) {
      const payload = {
        title: 'Success',
        message: userMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetUserMessage())
    }
  }, [userMessage])

  useEffect(() => {
    if (userMessage !== PASSWORD_CHANGE_CONFIRMATION && (error && error.message === 'token invalid')) {
      const payload = {
        title: 'Session Expired',
        message: 'Refreshing your session',
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [error])

  useEffect(() => {
    if (userError && userError.errors) {
      const payload = {
        title: 'Error',
        message: `Kindly try again, ${userError.errors.message}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [userError])

  useEffect(() => {
    if (userError && userError?.user && userError?.user?.message) {
      const payload = {
        title: 'Error',
        message: `Kindly try again, ${userError?.user?.message}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [userError])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getMyProfile({ token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && updatedUser && userId === updatedUser.id) {
      dispatch(setCurrentUser({ ...updatedUser, token }))
      dispatch(getMyProfile({ token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [updatedUser])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyId && (isOwner || hasPermission(appModules.COMPANIES, role, companyAccessPermissions, defaultAccessPermissions))) {
      dispatch(getCompanyById({ id: companyId, token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [address])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllSalutations({ token, perPage: 20, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [salutation, salutationMessage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllTitles({ token, perPage: 20, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [title, titleMessage])

  return (
    <div className="container-fluid px-4 py-4">
      <div className="card">
        <div className="card-body">
          <div>
            <div id="aboutMe">
              {!profile?.isVerified && (
                <div className="border border-primary rounded mb-3">
                  {isLoading && <Progress marginBottom={false} />}
                  <div className="p-2 mb-3">
                    <h6 className="mb-3 ps-2 mt-2 text-uppercase">
                      <i className="bi bi-info-circle me-1"></i> Pending
                      Actions
                    </h6>
                    <div className="border border-gray rounded p-2 m-2">
                      <p className="ps-2 mt-2">
                        Request a verification OTP to verify your email.{' '}
                        <br />
                        <span className="text-muted small">
                          You will be redirected to log in again once your
                          email is verified
                        </span>
                      </p>
                      <div className="card-body">
                        <Formik
                          validationSchema={verificationSchema}
                          initialValues={{ otp: '' }}
                          onSubmit={({ otp }, actions) => {
                            verify(
                              String(profile?.email),
                              parseInt(otp, 10)
                            )
                          }}
                        >
                          {({
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            values,
                            handleSubmit
                          }) => (
                            <div className="row">
                              <form
                                className="row gx-2"
                                onSubmit={handleSubmit}
                              >
                                <div className="col-auto mb-3">
                                  <label
                                    htmlFor="otp"
                                    className="visually-hidden"
                                  >
                                    OTP
                                  </label>
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.otp}
                                    className={`form-control ${
                                      errors.otp &&
                                      touched.otp &&
                                      errors.otp
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    type="text"
                                    id="otp"
                                    name="otp"
                                    placeholder="OTP"
                                  />
                                  <div
                                    id="validationOtpFeedback"
                                    className="invalid-feedback"
                                  >
                                    {errors.otp}
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <button
                                    disabled={isLoading}
                                    type="submit"
                                    className="btn btn-primary mb-3"
                                  >
                                    Verify Email
                                  </button>
                                </div>
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    disabled={isLoading}
                                    onClick={() => {
                                      const controller =
                                        new AbortController()
                                      const signal = controller.signal
                                      const user = {
                                        email: profile?.email
                                      }
                                      if (token && userId) {
                                        dispatch(
                                          sendVerifyCode({
                                            id: userId,
                                            token,
                                            user,
                                            signal
                                          })
                                        )
                                      }
                                    }}
                                  >
                                    Request Verification OTP
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div id="edit-profile">
              <ProfileEditor
                profile={profile}
                token={token}
                isLoading={isLoading}
                isUpdating={isUpdating}
                addresses={profile?.addresses || []}
                profileTitle={profile?.title || ''}
              />

              <div className="border border-gray rounded mb-3">
                {(isLoading || isUpdating) && <Progress marginBottom={false} />}
                <div className="p-3">
                  <Formik
                    validationSchema={passwordSchema}
                    enableReinitialize
                    initialValues={{
                      currentPassword: '',
                      password: ''
                    }}
                    onSubmit={(
                      { currentPassword, password },
                      actions
                    ) => {
                      const controller = new AbortController()
                      const signal = controller.signal
                      const user = {
                        currentPassword,
                        password
                      }
                      if (token && userId) {
                        dispatch(
                          updateUserPasswordById({
                            id: userId,
                            token,
                            user,
                            signal
                          })
                        )
                      }
                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <h6 className="mb-4 text-uppercase">
                          <i className="bi bi-pencil-square me-1"></i>{' '}
                          Update Password
                        </h6>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="currentPassword"
                                className="form-label"
                              >
                                Current Password
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.currentPassword}
                                type="password"
                                className={`form-control ${
                                  errors.currentPassword &&
                                  touched.currentPassword &&
                                  errors.currentPassword
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="currentPassword"
                                name="currentPassword"
                                placeholder="Enter Current Password"
                              />
                              <div
                                id="validationCurrentPasswordFeedback"
                                className="invalid-feedback"
                              >
                                {errors.currentPassword || error?.errors?.currentPassword}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="newPassword"
                                className="form-label"
                              >
                                New Password
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                type="password"
                                className={`form-control ${
                                  errors.password &&
                                  touched.password &&
                                  errors.password
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="newPassword"
                                name="password"
                                placeholder="Enter New Password"
                              />
                              <div
                                id="validationNewPasswordFeedback"
                                className="invalid-feedback"
                              >
                                {errors.password || error?.errors?.password}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-primary mt-2"
                            disabled={
                              isSubmitting || isUpdating || isLoading
                            }
                          >
                            <i className="bi bi-save"></i> Update
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="modal fade" id="salutationModal" tabIndex={-1} role="dialog" aria-labelledby="salutationModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="salutationModalLabel">Add Salutation</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {isLoadingSalutations && <Progress marginBottom={false} />}
                    <div className="modal-body">
                      <SalutationEditor id='' name='' save={addSalutation} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="salutationEditModal" tabIndex={-1} role="dialog" aria-labelledby="salutationEditModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="salutationEditModalLabel">Edit Salutation</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {isLoadingSalutations && <Progress marginBottom={false} />}
                    <div className="modal-body">
                      <ul className="list-group">
                        {
                          isLoadingSalutations
                            ? (
                              <li className="list-group-item">
                                <p className="placeholder-glow">
                                  <span className="placeholder col-12"></span>
                                </p>
                              </li>
                              )
                            : (
                                salutations.length > 0
                                  ? (
                                      salutations.map((salutation) => (
                                        <li key={salutation.id} className="list-group-item d-flex justify-content-between align-items-start">
                                          <div className="ms-2 me-auto align-self-center">
                                            <div className="fw-bold">{salutation.name}</div>
                                          </div>
                                          <div className="d-flex flex-row" role="group" aria-label="Salutation Actions">
                                            <button
                                              type="button"
                                              className="btn btn-outline-secondary btn-round btn-sm me-2"
                                              data-bs-toggle="modal"
                                              data-bs-target="#salutationEditorModal"
                                              onClick={() => {
                                                setSelectedSalutation(salutation)
                                              }}
                                            >
                                              <PencilIcon />
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-outline-danger btn-round btn-sm"
                                              onClick={() => {
                                                if (token) {
                                                  const controller = new AbortController()
                                                  const signal = controller.signal
                                                  dispatch(deleteASalutationById({ token, salutationId: salutation.id, signal }))
                                                }
                                              }}
                                            >
                                              <TrashIcon />
                                            </button>
                                          </div>
                                        </li>
                                      ))
                                    )
                                  : (
                                    <li className="list-group-item">
                                      <div className="ms-2 me-auto align-self-center">
                                        <div>No salutations available yet</div>
                                      </div>
                                    </li>
                                    )
                              )
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="salutationEditorModal" tabIndex={-1} aria-labelledby="salutationEditorModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="salutationEditorModalLabel">{`Edit Salutation '${selectedSalutation.name}'`}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {isLoadingSalutations && <Progress marginBottom={false} />}
                    <div className="modal-body">
                      <SalutationEditor
                        id={selectedSalutation.id}
                        name={selectedSalutation.name}
                        save={editASalutationById}
                        mode="edit"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="titleModal" tabIndex={-1} role="dialog" aria-labelledby="titleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="titleModalLabel">Add Title</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {isLoadingTitles && <Progress marginBottom={false} />}
                    <div className="modal-body">
                      <TitleEditor id='' name='' save={addTitle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="titleEditModal" tabIndex={-1} role="dialog" aria-labelledby="titleEditModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="titleEditModalLabel">Edit Title</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {isLoadingTitles && <Progress marginBottom={false} />}
                    <div className="modal-body">
                      <ul className="list-group">
                        {
                          isLoadingTitles
                            ? (
                              <li className="list-group-item">
                                <p className="placeholder-glow">
                                  <span className="placeholder col-12"></span>
                                </p>
                              </li>
                              )
                            : (
                                titles.length > 0
                                  ? (
                                      titles.map((title) => (
                                        <li key={title.id} className="list-group-item d-flex justify-content-between align-items-start">
                                          <div className="ms-2 me-auto align-self-center">
                                            <div className="fw-bold">{title.name}</div>
                                          </div>
                                          <div className="d-flex flex-row" role="group" aria-label="Title Actions">
                                            <button
                                              type="button"
                                              className="btn btn-outline-secondary btn-round btn-sm me-2"
                                              data-bs-toggle="modal"
                                              data-bs-target="#titleEditorModal"
                                              onClick={() => {
                                                setSelectedTitle(title)
                                              }}
                                            >
                                              <PencilIcon />
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-outline-danger btn-round btn-sm"
                                              onClick={() => {
                                                if (token) {
                                                  const controller = new AbortController()
                                                  const signal = controller.signal
                                                  dispatch(deleteTitleById({ token, titleId: title.id, signal }))
                                                }
                                              }}
                                            >
                                              <TrashIcon />
                                            </button>
                                          </div>
                                        </li>
                                      ))
                                    )
                                  : (
                                    <li className="list-group-item">
                                      <div className="ms-2 me-auto">
                                        <div>No titles available yet</div>
                                      </div>
                                    </li>
                                    )
                              )
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="titleEditorModal" tabIndex={-1} aria-labelledby="titleEditorModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="titleEditorModalLabel">{`Edit Title '${selectedTitle.name}'`}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {isLoadingTitles && <Progress marginBottom={false} />}
                    <div className="modal-body">
                      <TitleEditor
                        id={selectedTitle.id}
                        name={selectedTitle.name}
                        save={editTitleById}
                        mode="edit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
